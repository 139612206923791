// Dropdown.js
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Dropdown = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col justify-center border-b-2 xl:p-[30px] py-[15px] px-2.5">
        
      <div className="flex justify-between items-center gap-2.5 w-full" onClick={toggleDropdown}>
        <p className='xl:text-[20px] text-[16px] text-black'>{title}</p>
        <span className="cursor-pointer text-tangerine">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </div>

      {isOpen && (
        <div className="flex flex-col xl:text-[16px] text-[14px] text-[#4E4E4E]">
          {content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
