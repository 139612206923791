import React from 'react';
import './styles.css';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


import dots from '../../assets/dots.svg'

const SocialLink = ({ url, icon }) => (
    <a href={url} target="_blank" rel="noopener" className='text-2xl hover:border-2 hover:border-white hover:rounded-md hover:p-1 transition-one'>
        {icon}
    </a>
);

const VolunteersTeamMember = ({ name, role, image, socialLinks, bgColor }) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (

        <motion.div
            ref={ref}
            animate={inView ? 'visible' : 'hidden'}
            variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
            }}
            transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
        >
            <div className="flip-card bg-transparent xl:w-[280px] xl:h-[450px] w-[250px] h-[350px] rounded-lg">

                <div className="flip-card-inner relative w-full h-full text-center transition-transform transform-style-preserve-3d shadow-md rounded-lg">

                    <div className={`flip-card-front text-black absolute overflow-hidden w-full h-full backface-hidden rounded-lg`}>
                        <div className={`absolute xl:left-[-110px] left-[-100px] xl:w-[500px] xl:h-[350px] w-[450px] h-[250px] flex flex-col gap-5 items-center justify-center bg-cover rounded-b-full ${bgColor} shadow-lg`}>
                            <div className="xl:w-[200px] xl:h-[200px] w-[150px] h-[150px] bg-center bg-cover rounded-full" style={{ backgroundImage: `url(${image})` }} />
                            <p className="text-white font-bold font-pressura">{role}</p>
                        </div>

                        <div className='absolute flex flex-col justify-center items-center w-full bottom-[40px] gap-2.5 text-black'>
                            <div className="w-[80px] h-[25px] border-2 border-violet rounded-full flex justify-center items-center ">
                                <img src={dots} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className={`flip-card-back flex flex-col items-center gap-5 text-white ${bgColor} absolute w-full h-full rounded-lg p-5`}>

                        <div className="xl:w-[200px] xl:h-[200px] w-[100px] h-[100px] bg-center bg-cover rounded-full" style={{ backgroundImage: `url(${image})` }} />
                        <div className="">
                            <p className="font-agrandir font-bold">{name}</p>
                            <p className="font-pressura">{role}</p>
                        </div>
                        <p className='font-agrandir'>-.-.-</p>

                        <div className="flex xl:gap-2.5 gap-5 justify-center items-center">
                            {socialLinks.map((link, index) => (
                                <SocialLink key={index} {...link} />
                            ))}
                        </div>
                    </div>

                </div>

            </div>
        </motion.div>
    )
}

export default VolunteersTeamMember