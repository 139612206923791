import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import WebsiteLayout from './layouts/website/WebsiteLayout';
import Home from './pages/website/Home';
import We from './pages/website/we/We';
import Members from './pages/website/Members';
import Events from './pages/website/events/Events';
import Blog from './pages/website/blog/Blog';
import Article from './pages/website/blog/Article';
import Contact from './pages/website/Contact';
import Documentation from './pages/website/Documentation';
import PrivacyPolicy from './pages/website/PrivacyPolicy';
import SiteMap from './pages/website/SiteMap';
import Event from './pages/website/events/Event';
import Signin from './pages/app/auth/Signin';
import AuthLayout from './layouts/app/AuthLayout';
import DashboardLayout from './layouts/app/DashboardLayout';
import Board from './pages/app/dashboard/Board';
import { AuthProvider } from './pages/app/AuthContext';
import { auth } from './utils/firebase';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import PrivateRoute from './pages/app/PrivateRoute';
import BlogApp from './pages/app/dashboard/blog/BlogApp';
import BlogArticleView from './pages/app/dashboard/blog/BlogArticleView';
import NewArticle from './pages/app/dashboard/blog/NewArticle';
import EditArticle from './pages/app/dashboard/blog/EditBlogArticle';
import EventsBlog from './pages/app/dashboard/events/EventsBlog';
import ErrorPage from './pages/ErrorPage';
import Users from './pages/app/dashboard/users/Users';
import NewEvent from './pages/app/dashboard/events/NewEvent';
import EventView from './pages/app/dashboard/events/EventView';
import PasswordRecovery from './pages/app/auth/PasswordRecovery';
import AddUser from './pages/app/dashboard/users/AddUser';
import EditEvent from './pages/app/dashboard/events/EditEvent';
import Inbox from './pages/app/dashboard/inbox/Inbox';
import Message from './pages/app/dashboard/inbox/Message';
import Docs from './pages/app/dashboard/docs/Docs';
import AddDocument from './pages/app/dashboard/docs/AddDocument';
import ArchivedEvent from './pages/app/dashboard/events/ArchivedEvent';
import ArchivedArticle from './pages/app/dashboard/blog/ArchivedArticle';
import DevPro from './pages/website/devpro/DevPro';
import JobsApp from './pages/app/dashboard/jobs/JobsApp';
import NewJobOffer from './pages/app/dashboard/jobs/NewJobOffer';
import JobDetail from './pages/website/devpro/JobDetail';
import JobView from './pages/app/dashboard/jobs/JobView';
import JobEdit from './pages/app/dashboard/jobs/JobEdit';
import ArchivedJobs from './pages/app/dashboard/jobs/ArchivedJobs';


function App() {

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])


  return (
    <main>
      <BrowserRouter>

        <AuthProvider value={{ currentUser }}>
          <Routes>

            <Route path="/" element={<WebsiteLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="we" element={<We />} />
              <Route path="members" element={<Members />} />

              <Route path="events" element={<Events />} />
              <Route path="events/event/:id" element={<Event />} />

              <Route path="blog" element={<Blog />} />
              <Route path="blog/article/:id" element={<Article />} />
              <Route path="blog/docs" element={<Documentation />} />

              <Route path="contact" element={<Contact />} />

              <Route path="devpro" element={<DevPro />} />
              <Route path="devpro/jobdetail/:id" element={<JobDetail />} />

              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="site-map" element={<SiteMap />} />
            </Route>

            <Route path="/auth" element={<AuthLayout />}>
              <Route path="/auth" element={<Signin />} />
              <Route path="passwordrecovery" element={<PasswordRecovery />} />
            </Route>

            <Route path="/dashboard/" element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }>

              <Route path="/dashboard/" element={<Board />} />

              <Route path="blogapp/" element={<BlogApp />} />
              <Route path="blogapp/new-article" element={<NewArticle />} />
              <Route path="blogapp/view/:id" element={<BlogArticleView />} />
              <Route path="blogapp/edit/:id" element={<EditArticle />} />
              <Route path="blogapp/archives" element={<ArchivedArticle />} />

              <Route path="eventapp/" element={<EventsBlog />} />
              <Route path="eventapp/new-event" element={<NewEvent />} />
              <Route path="eventapp/eventview/:id" element={<EventView />} />
              <Route path="eventapp/edit/:id" element={<EditEvent />} />
              <Route path="eventapp/archives" element={<ArchivedEvent />} />

              <Route path="inbox/" element={<Inbox />} />
              <Route path="inbox/message/:id" element={<Message />} />

              <Route path="docs/" element={<Docs />} />
              <Route path="docs/add-doc" element={<AddDocument />} />

              <Route path="users/" element={<Users />} />
              <Route path="users/adduser" element={<AddUser />} />

              <Route path="jobs/" element={<JobsApp />} />
              <Route path="jobs/new-joboffer" element={<NewJobOffer />} />
              <Route path="jobs/jobview/:id" element={<JobView />} />
              <Route path="jobs/edit/:id" element={<JobEdit />} />
              <Route path="jobs/archives" element={<ArchivedJobs />} />

            </Route>

            <Route path="*" element={<ErrorPage />} />

          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </main>

  );
}

export default App;
