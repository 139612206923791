import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';


const ObjectifMission = ({ symbole, title, content, color }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });


    return (

        <>
            <motion.div
                ref={ref}
                transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 150 },
                }}
            >
                <div className="xl:grid hidden items-center justify-center w-[800px]">

                    <div className="mb-[-130px] ml-[-50px] bg-white rounded-[50%] border-2 shadow-md h-[140px] w-[140px] flex items-center justify-center">
                        <img src={symbole} className='h-[60px] w-[60px]' alt="" />
                    </div>

                    <div className={`flex flex-col justify-center w-full -z-10 font-agrandir text-white py-2.5 h-[120px] pl-[120px] pr-5 bg-${color} rounded-2xl`}>
                        <span className='text-[18px] font-bold'>{title}</span>
                        <p className='text-[14px]'>
                            {content}
                        </p>
                    </div>

                </div>

                <div className={`xl:hidden flex flex-col bg-${color} items-center justify-center w-[90vw] max-w-[450px] transition ${isOpen ? 'rounded-[40px] h-full' : 'rounded-full h-[70px]'} `}>
                    <div className="flex justify-between items-center w-full pr-[20px]" onClick={toggleDropdown}>

                        <div className={`bg-white rounded-[50%] border-2 shadow-md h-[80px] w-[80px] flex items-center justify-center ml-[-10px]`}>
                            <img src={symbole} className='h-[40px] w-[40px]' alt="" />
                        </div>

                        <p className='flex font-bold xl:text-[18px] text-[14px] text-white font-agrandir'>{title}</p>

                        <span className={`text-[30px] cursor-pointer text-white drop_toggle`}>
                            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </span>

                    </div>

                    {isOpen && (
                        <div className="flex flex-col justify-center items-center xl:text-[16px] text-[14px] text-white p-5">
                            <p className="text-[16px] font-agrandir text-justify">
                                {content}
                            </p>
                        </div>
                    )}
                </div>
            </motion.div>
        </>
    )
}

export default ObjectifMission