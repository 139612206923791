import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { FaAnglesLeft } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { firestoreDatabase, storage } from '../../../../utils/firebase';
import { addDoc, collection } from 'firebase/firestore';
import Modal from '../../../../components/modal/Modal';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdOutlineSmsFailed } from 'react-icons/md';

const AddDocument = () => {

    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true); // Afficher le loader

            // Télécharger la photo de profil sur Firebase Storage
            const fileRef = ref(storage, `uploads/${file.name}`);
            await uploadBytes(fileRef, file);

            // Récupérer l'URL de téléchargement de la photo de profil
            const fileUrl = await getDownloadURL(fileRef);

            // Adding the article to Firebase Firestore
            await addDoc(collection(firestoreDatabase, "docs"), {
                docName: file.name,
                fileUrl: fileUrl,
                createdAt: new Date(),
            });

            setIsSent(true);
            setIsLoading(false);
            setOpen(true)

            setFile('');

        } catch (error) {
            console.error("Error adding article: ", error);
            setIsSent(false);
            setIsLoading(false);
            setOpen(true);
        }
    };

    return (
        <div className="flex flex-col">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Documentation</p>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

                <div className='bg-white flex flex-col items-center gap-[15px] px-[60px] w-full'>

                    <p className='font-bold text-violet'>Ajouter un document</p>

                    <form onSubmit={handleUpload} className='flex justify-center items-center flex-col gap-5 font-agrandir'>

                        <div className='form-ctrl'>
                            <input type="file"
                                className='outline-none border-2 px-4 py-2 rounded-md w-[400px]'
                                onChange={handleFileChange}
                                required
                            />
                        </div>

                        <button className="buttonviolet w-[200px!important]">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Charger le document</p>
                            )}
                        </button>

                    </form>

                </div>

            </section>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            {isSent ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>Votre document a été chargé !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}
                            <button
                                className='buttonviolet w-[50px!important]'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }

        </div>
    )
}

export default AddDocument