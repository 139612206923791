import React from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { Link } from "react-router-dom";

const ArticleCard = ({ id, coverImg, title, content, date }) => {
    return (
        <div className="bg-lightgrey flex xl:flex-row flex-col gap-5 xl:px-[30px] px-[20px] xl:py-[50px] py-[20px] xl:h-[300px] w-full rounded-md shadow-md">

            <div className="xl:w-3/6 w-full h-[200px] bg-cover bg-center rounded-md" style={{ backgroundImage: `url(${coverImg})` }} />

            <div className="xl:w-3/6 h-[200px] flex flex-col relative">

                <p className="title font-pressura text-tangerine text-[22px]">{title}</p>

                <p className="font-agrandir text-grey text-[14px] mt-[0px]">{date}</p>

                <p className="font-agrandir text-black text-[16px] mt-[5px] h-[60%] text-justify overflow-hidden text-ellipsis" dangerouslySetInnerHTML={{ __html: content }} />

                <Link to={"/blog/article/" + id+"#article"} className="xl:absolute bottom-0 font-pressura cursor-pointer flex items-center text-violet text-[15px] hover:gap-1">
                    Continuer la lecture
                    <FaAngleDoubleRight className='text-[10px]' />
                </Link>
            </div>
        </div> 
    )
}

export default ArticleCard