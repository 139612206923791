import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import datapmi from '../../assets/csv/Current_Month_Active_Members_List.csv';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
const PmiTogoData = () => {

    const [csvData, setCsvData] = useState([]);
    const today = new Date();
    const thisYear = today.getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(datapmi);
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csvString = decoder.decode(result.value);

            Papa.parse(csvString, {
                header: true,
                dynamicTyping: true,
                complete: function (results) {
                    setCsvData(results.data);
                }
            });
        };

        fetchData();
    }, []);

    const joinedThisYear = csvData.filter(member => {
        const joinDate = member["Month(Chapter Join Date)"];
        return joinDate && joinDate.includes(thisYear);
    }).length;

    const totalMembers = csvData.length;

    const certifiedMembers = csvData.filter(member => {
        const certificationStatus = member["Certification Status for Person"];
        return certificationStatus && certificationStatus === "ACTIVE";
    }).length;

    const studentMembers = csvData.filter(member => {
        const classification = member["Chapter Membership Classification"];
        return classification && classification === "Student";
    }).length;

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <section className="flex flex-col justify-between items-center gap-[30px] max-w-[1440px]">
            <p className="font-agrandir xl:text-[24px] text-[16px] text-black">Statistiques d'adhésion</p>
            <motion.div
                ref={ref}
                transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                }}
            >
                <div className="w-full xl:grid xl:grid-cols-4 gap-[40px] xl:px-[220px] xl:justify-evenly max-w-[1440px] items-center flex flex-col justify-center">

                    <div className="bg-lightgrey flex flex-col justify-around items-center gap-2.5 px-[15px] xl:w-[200px] xl:h-[120px] w-[150px] h-[90px]">
                        <p className="text-black xl:text-[16px] text-[14px] font-agrandir text-center">
                            Membres de cette année
                        </p>

                        <span className="text-aqua font-bold xl:text-[24px] text-[20px]">
                            {inView && <CountUp start={0} end={joinedThisYear} duration={2.5} />}
                        </span>
                    </div>

                    <div className="bg-lightgrey flex flex-col justify-around items-center gap-2.5 px-[15px] xl:w-[200px] xl:h-[120px] w-[150px] h-[90px]">
                        <p className="text-black xl:text-[16px] text-[14px] font-agrandir text-center">
                            Total des Membres 
                        </p>

                        <span className="text-violet font-bold xl:text-[24px] text-[20px]">
                            {inView && <CountUp start={0} end={totalMembers} duration={2.5} />}
                        </span>
                    </div>

                    <div className="bg-lightgrey flex flex-col justify-around items-center gap-2.5 px-[15px] xl:w-[200px] xl:h-[120px] w-[150px] h-[90px]">
                        <p className="text-black xl:text-[16px] text-[14px] text-center font-agrandir">
                            Membres Certifiés
                        </p>

                        <span className="text-tangerine font-bold xl:text-[24px] text-[20px]">
                            {inView && <CountUp start={0} end={certifiedMembers} duration={2.5} />}
                        </span>
                    </div>

                    <div className="bg-lightgrey flex flex-col justify-around items-center gap-2.5 px-[15px] xl:w-[200px] xl:h-[120px] w-[150px] h-[90px]">
                        <p className="text-black xl:text-[16px] text-[14px] font-agrandir text-center">
                            Etudiants
                        </p>

                        <span className="text-aqua font-bold xl:text-[24px] text-[20px]">
                            {inView && <CountUp start={0} end={studentMembers} duration={2.5} />}
                        </span>
                    </div>

                </div>

            </motion.div>
        </section>
    )
}

export default PmiTogoData