import React, { useEffect, useState } from 'react';
import LocalEventCard from '../../../components/LocalEvent/LocalEventCard';
import WebEvent from '../../../components/webevent/WebEvent';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../../utils/firebase';
import { fr } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { FaAnglesLeft } from 'react-icons/fa6';



const Events = () => {

    const [localEvents, setLocalEvents] = useState([]);

    //read local events data from firebase
    useEffect(() => {
        const readLocalEventsData = async () => {
            const localeventsRef = query(collection(firestoreDatabase, 'events'), orderBy('date', 'desc'), where('isVisible', '==', true), where('type', '==', 'presential')) 
            onSnapshot(localeventsRef, (snapshot) => {
                setLocalEvents(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readLocalEventsData();
    }, []);

    const [webEvents, setWebEvents] = useState([]);

    //read webinaries data from firebase
    useEffect(() => {
        const readWebEventsData = async () => {
            const webeventsRef = query(collection(firestoreDatabase, 'events'), orderBy('date', 'desc'), where('isVisible', '==', true), where('type', '==', 'online'))
            onSnapshot(webeventsRef, (snapshot) => {
                setWebEvents(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readWebEventsData();
    }, []);

    const navigate = useNavigate();

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });


    return (
        <div className="flex flex-col w-full xl:gap-[100px] gap-[50px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='evenements'></div>

            <section className="flex flex-col xl:gap-[50px]" id=''>
                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-violet slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-violet xl:px-[60px] px-5 xl:w-[1440px] slide-left">EVENEMENTS</p>
                    </div>
                </motion.div>
                <div className="xl:flex hidden xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-violet text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className="w-full grid xl:justify-center xl:gap-[100px] gap-[50px]">

                <section className="flex flex-col gap-[30px] max-w-[1440px]" id='localevents'>
                    <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Evènements locaux</p>

                    <div className="flex flex-col justify-center items-center xl:gap-[50px] gap-10 xl:px-[160px] px-[20px]">

                        {localEvents.length > 0 ?
                            localEvents.map((event, index) => (
                                <LocalEventCard
                                    key={index} {...event}
                                    id={event.id}
                                    coverImg={event.data.coverUrl}
                                    title={event.data.eventName}
                                    date={format(parseISO(event.data.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                                    location={event.data.eventLinkOrPlace}
                                    mapLink={event.data.eventMapLink} />
                            ))
                            :
                            <div className="flex justify-center xl:w-[800px] w-full font-agrandir xl:text-[18px] text-[15px] xl:pb-[100px] pb-[80px]">
                                <div className="flex flex-col">
                                    Aucun évènement local annoncé !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>


                </section>

                <section className="flex flex-col gap-[30px] max-w-[1440px]" id='webinaires'>
                    <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Webinaires</p>

                    <div className="flex flex-col justify-center xl:items-center gap-[30px] xl:px-[160px] px-[20px]">

                        {webEvents.length > 0 ?
                            webEvents.map((event, index) => (
                                <WebEvent key={index} {...event}
                                    id={event.id}
                                    coverImg={event.data.coverUrl}
                                    title={event.data.eventName}
                                    date={format(parseISO(event.data.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                                    link={event.data.eventLinkOrPlace} />
                            ))
                            :
                            <div className="flex justify-center xl:w-[800px] w-full font-agrandir xl:text-[18px] text-[15px] xl:pb-[100px] pb-[80px]">
                                <div className="flex flex-col">
                                    Aucun webinaire annoncé !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>

                </section>

            </div>

        </div>
    )
}

export default Events