import React from 'react'
import Header from '../../components/header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import ScrollToTopButton from '../../components/scroll-to-top/ScrollToTop'

const WebsiteLayout = () => {
  return (
    <div>
      <Header />
      
      <div className=" overflow-hidden">
        <Outlet />
      </div>

      <ScrollToTopButton />

      <Footer />
    </div>
  )
}

export default WebsiteLayout