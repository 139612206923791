import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Timestamp, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../../../utils/firebase';
import { format } from 'date-fns';
import { fr } from "date-fns/locale";
import { FaAnglesLeft } from 'react-icons/fa6';
import Modal from '../../../../components/modal/Modal';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdCalendarToday, MdOutlinePerson2, MdOutlineSmsFailed } from 'react-icons/md';


function BlogArticleView() {

    const { id } = useParams();
    const [article, setArticle] = useState([]);
    const [isVisible, setIsVisible] = useState('');
    const [isDone, setIsDone] = useState(false);

    //read this article data
    useEffect(() => {
        const readThisArticleData = async () => {
            const docRef = doc(firestoreDatabase, 'articles', id);
            const docSnap = await getDoc(docRef);
            setArticle(docSnap.data());
            setIsVisible(docSnap.data().isVisible);
        };
        readThisArticleData();
    }, [id]);

    const articleCover = { backgroundImage: `url(${article?.coverUrl})` };

    //Archiver un article
    const [isArchiving, setIsArchiving] = useState(false);

    const archivateArticle = async (event) => {
        event.preventDefault();
        setIsArchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'articles', id);

        try {
            // Attendre la mise à jour de l'article
            await updateDoc(articleDocRef, {
                isVisible: false,
                lastModif: Timestamp.now(),
            });

            // La mise à jour de l'article est réussie, nous pouvons réinitialiser setIsArchiving
            setIsArchiving(false);
            navigate(-1);
        } catch (error) {
            setIsArchiving(false);
            console.error("Error updating article: ", error);
            // Vous pouvez ajouter une logique de gestion d'erreur ici, comme afficher un message d'erreur à l'utilisateur.
        }
    };

    //Désarchiver un article
    const [isDisarchiving, setIsDisarchiving] = useState(false);

    const desarchivateArticle = async (event) => {
        event.preventDefault();
        setIsDisarchiving(true); // Afficher le loader

        const articleDocRef = doc(firestoreDatabase, 'articles', id);

        try {
            // Attendre la mise à jour de l'article
            await updateDoc(articleDocRef, {
                isVisible: true,
                lastModif: Timestamp.now(),
            });

            // La mise à jour de l'article est réussie, nous pouvons réinitialiser setIsArchiving
            setIsDisarchiving(false);
            navigate(-1);
        } catch (error) {
            setIsDisarchiving(false);
            console.error("Error updating article: ", error);
            // Vous pouvez ajouter une logique de gestion d'erreur ici, comme afficher un message d'erreur à l'utilisateur.
        }
    };

    const deleteArticle = async () => {
        try {

            // Accédez au document dans Firestore
            const docRef = doc(firestoreDatabase, 'articles', id);

            // Supprimez le document
            await deleteDoc(docRef);
            setIsDone(true);
            setOpen(true);

            // Vous pouvez également effectuer d'autres actions après la suppression, comme rediriger l'utilisateur ou afficher un message de confirmation.
        } catch (error) {
            setIsDone(false);
            setOpen(true);
            // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
        }
    };

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    }


    return (
        <div className="px-[60px]">
            <section className="flex pb-[30px]" id='blog'>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <section className="bg-white flex flex-col gap-[30px]">

                <div className="flex flex-col gap-[10px] pb-[150px]">

                    <div className="w-full bg-cover rounded-md xl:h-[400px]" style={articleCover} />

                    <div className="flex gap-5 text-grey">
                        <div className="flex items-center">
                            <MdOutlinePerson2 />
                            <p className="">{article?.author}</p>
                        </div>

                        <div className="flex items-center">
                            <MdCalendarToday />
                            <p className="">
                                {article?.createdAt && format(article.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2.5">
                        <p className="font-pressura xl:text-[28px] text-[20px] text-black">{article?.title}</p>

                        <div className="flex items-center justify-center text-violet text-[12px] py-[2px] px-2 w-fit border-[1px] border-violet rounded-xl">{article?.rubrique}</div>

                        <p className="font-agrandir xl:text-[16px] text-[16px] text-black text-justify" dangerouslySetInnerHTML={{ __html: article?.content }}></p>

                    </div>

                </div>

                <div className="flex gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/blogapp/edit/" + id} className='buttonblack'>Modifier</Link>

                    {isVisible ? (
                        <button
                            onClick={archivateArticle}
                            className='buttonblack'
                            type='button'
                        >
                            {isArchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Archiver</p>
                            )}
                        </button>

                    ) : (

                        <button
                            onClick={desarchivateArticle}
                            className='buttonblack'
                            type='button'
                        >
                            {isDisarchiving ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Désarchiver</p>
                            )}
                        </button>
                    )}

                    {isVisible === false && (
                        <button
                            className='buttonblack'
                            type='button'
                            onClick={deleteArticle}
                        >
                            Supprimer
                        </button>
                    )}
                </div>

            </section>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            {isDone ? (
                                <>
                                    <BsCheck2Circle className='text-green-700 text-[50px]' />

                                    <p>L'article a été supprimé !</p>
                                </>
                            ) : (
                                <>
                                    <MdOutlineSmsFailed className='text-red-700 text-[50px]' />

                                    <p>Une erreur s'est produite, veuillez réessayer !</p>
                                </>
                            )}
                            <button
                                className='buttonviolet w-[50px!important]'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>
                        </div>
                    </center>
                </Modal>
            }
        </div>
    )
}

export default BlogArticleView