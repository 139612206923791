import React, { useEffect, useState } from 'react'
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";
import icon from "../../assets/logo/favicon.png";
import { Link, NavLink, useLocation } from 'react-router-dom';
import Dropdown from '../dropdown/Dropdown';
import { FaChevronDown } from 'react-icons/fa';
import { IoIosMenu } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';

const Header = () => {

  const location = useLocation();
  const activeSidenavLinkCss = "text-tangerine";
  const sidenavLinkCss = "";

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Faire défiler vers l'élément avec l'ID correspondant au hachage dans l'URL
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const Menu = [
    {
      label: 'ACCUEIL',
      link: '/#hero',
    },
    {
      label: 'PMI TOGO CHAPTER',
      link: '/we#we',
      links: [
        { label: "Qui Sommes-nous ?", link: "/we#we", out: false },
        { label: "Objectifs & Missions", link: "/we#objectifs&missions", out: false },
        { label: "Membres dirigeants", link: "/we#headmember", out: false },
        { label: "Volontaires", link: "/we#volunteers", out: false },
      ],
    },

    {
      label: 'MEMBRES',
      link: '/members#member',
      links: [
        { label: "Avantages", link: "/members#advantages", out: false },
        { label: "Volontariat", link: "/members#volunteering", out: false },
      ],
    },

    {
      label: 'CARRIERE',
      link: '/devpro#prodev',
      links: [
        { label: "Certifications", link: "/devpro#certifications", out: false },
        { label: "Formations", link: "/devpro#formations", out: false },
        { label: "Offres d'emploi", link: "/devpro#jobs", out: false },
      ],
    },

    {
      label: 'EVENEMENTS',
      link: '/events#evenements',
      links: [
        { label: "Evènements locaux", link: "/events#localevents", out: false },
        { label: "Webinaires", link: "/events#webinaires", out: false },
        { label: "PMI Africa Conférence", link: "https://pmiafricaconference.com/", out: true },
        { label: "PMI Global", link: "https://www.pmi.org/events/calendar", out: true },
      ],
    },

    {
      label: 'PUBLICATIONS',
      link: '/blog#blog',
      links: [
        { label: "Blog", link: "/blog#blog", out: false },
        { label: "Documentation", link: "blog/docs#docs", out: false },
      ],
    },

    {
      label: 'CONTACT',
      link: '/contact#contact',
    },
  ];

  return (
    <header className="bg-white shadow z-[48] relative">

      {/* Web header */}
      <section className="h-[20vh] hidden xl:grid items-center 2xl:justify-center max-h-[140px] bg-white shadow-lg fixed top-0 w-full" id='webNav'>
        <section className="h-[100px] flex justify-between px-[60px] py-[10px] border-b-2 border-lightgrey 2xl:max-w-[1440px]">
          <Link to={'/'}>
            <img src={logo} className='h-[80px]' alt="PMI Chapter Togo" />
          </Link>

          <div className="flex items-center justify-center gap-5 font-agrandir">
            <a href="https://idp.pmi.org/account/login" target="_blank" rel="noopener"
              className="flex items-center justify-center h-fit py-2.5 text-violet">Se Connecter</a>
            <div className="w-[2px] h-12 bg-gray-300 rounded"></div>
            <a href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter" target="_blank" rel="noopener"
              className="buttonviolet">Nous
              rejoindre</a>
          </div>
        </section>

        <nav className="h-[40px] hidden xl:flex px-[60px] items-center justify-end 2xl:max-w-[1980px]">
          <ul className="flex gap-[40px] h-full">

            {Menu.map((item, idx) => (
              item.links ? (
                <li className="relative group" key={idx}>
                  <NavLink className={({ isActive }) => isActive ? activeSidenavLinkCss : sidenavLinkCss} to={item.link}>
                    <div className="cursor-pointer flex gap-[5px] h-full items-center hover:text-violet font-pressura">
                      <p className="">{item.label}</p>
                      <FaChevronDown className='text-tangerine' />
                    </div>
                  </NavLink>
                  <div className="absolute hidden group-hover:block bg-white min-w-[250px] w-[max-content] shadow-2xl rounded-b-sm text-black font-agrandir">
                    {item.links.map((line, index) => (
                      line.out === true ?
                        <Link onClick={closeMenu} key={index} to={line.link} target="_blank" rel="noopener" className="block hover:bg-tangerine hover:text-white px-[10px] py-[6px]">{line.label}</Link>
                        :
                        <Link onClick={closeMenu} key={index} to={line.link} className="block hover:bg-aqua hover:text-white px-[10px] py-[6px]">{line.label}</Link>
                    ))}
                  </div>
                </li>
              ) : (
                <li className="relative group" key={idx}>
                  <NavLink className={({ isActive }) => isActive ? activeSidenavLinkCss : sidenavLinkCss} to={item.link}>
                    <div className="cursor-pointer flex gap-[5px] h-full items-center hover:text-violet font-pressura">
                      <p className="">{item.label}</p>
                      <span className="w-[5px] h-[5px] rounded-full bg-tangerine" />
                    </div>
                  </NavLink>
                </li>
              )
            ))}

          </ul>
        </nav>
      </section>

      {/* Mobile header */}
      <section className={`xl:hidden bg-white w-full flex flex-col gap-[15px] ${isOpen ? 'h-[100dvh] fixed overflow-y-scroll' : 'h-[10vh]'}`}>
        <div className={`flex bg-white justify-between items-center p-2.5 h-[10vh] shadow-md w-full ${isOpen ? '' : 'fixed'}`} id='mobileNav'>
          <Link to={'/'} onClick={closeMenu}>
            <img src={isOpen ? icon : logo} className='h-[40px] transition-one' alt="PMI Chapter Togo" />
          </Link>

          <span class="text-violet text-[42px]" onClick={toggleMenu}>
            {isOpen ? <MdOutlineClose /> : <IoIosMenu />}
          </span>
        </div>

        {isOpen && (
          <div className="flex flex-col bg-white justify-between h-full px-5 transition-one">
            <div className='flex flex-col w-full font-pressura'>

              {Menu.map((item, idx) => (
                item.links ? (

                  <Dropdown
                    key={idx}
                    title={
                      <NavLink className={({ isActive }) => isActive ? activeSidenavLinkCss : sidenavLinkCss} to={item.link}>
                        <div className="cursor-pointer flex gap-[5px] items-center">
                          <p className="">{item.label}</p>
                        </div>
                      </NavLink>
                    }
                    content={[
                      <div className="bg-white w-[max-content] text-black">

                        {item.links.map((line, index) => (
                          line.out === true ? 
                            <Link onClick={closeMenu} key={index} to={line.link} target="_blank" rel="noopener" className="block hover:bg-tangerine hover:text-white px-[10px] py-[6px]">{line.label}</Link>
                            :
                            <Link onClick={closeMenu} key={index} to={line.link} className="block hover:bg-aqua hover:text-white px-[10px] py-[6px]">{line.label}</Link>
                        ))}

                      </div>
                    ]}
                  />
                ) : (
                  <NavLink to={item.link} className={({ isActive }) => isActive ? 'flex flex-col justify-center border-b-2 py-[15px] px-2.5 text-tangerine' : 'flex flex-col justify-center border-b-2 py-[15px] px-2.5'} onClick={closeMenu}>

                    <div className="flex justify-between w-full">
                      <p className='xl:text-[20px] text-[16px]'>{item.label}</p>
                    </div>

                  </NavLink>
                )
              ))}

            </div>

            <div className="flex flex-col items-center justify-center gap-5 font-agrandir">
              <a href="https://idp.pmi.org/account/login" target="_blank" rel="noopener"
                className="flex items-center justify-center h-fit text-violet">Se Connecter</a>

              <a href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter" target="_blank" rel="noopener"
                className="flex items-center justify-center h-fit border-2 buttonviolet">Nous
                rejoindre</a>
            </div>

            <div className="border-t-2 mx-5">
              <p className="text-grey text-[10px] py-[10px] text-center">©2023 PMI Chapitre Togo . Tous droits réservés.</p>
            </div>

          </div>
        )}
      </section>
    </header>
  )
}

export default Header