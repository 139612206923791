import React, { useEffect, useState } from 'react';
import BlogArticleCard from './BlogArticleCard';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../../../components/modal/Modal';
import { Timestamp, addDoc, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../../../utils/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FaAnglesLeft } from 'react-icons/fa6';


const ArchivedArticle = () => {

    const [articles, setArticles] = useState([]);

    //read articles data from firebase
    useEffect(() => {
        const readArticlesData = async () => {
            const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', false))
            onSnapshot(articlesRef, (snapshot) => {
                setArticles(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readArticlesData();
    }, []);

    const [nom, setNom] = useState("");
    const [error, setError] = useState('');


    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const addNewRubrique = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true); // Afficher le loader

            // Adding to Firebase Firestore
            await addDoc(collection(firestoreDatabase, "categories"), {
                nom: nom,
                createdAt: Timestamp.now()
            });

            setShowSuccess(true);
        } catch (error) {
            setError(error);
        }
    };

    const navigate = useNavigate();

    return (

        <div className="flex justify-between">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Articles archivés</p>
                <section className="flex pb-[30px]" id='blog'>

                    <div className="xl:flex hidden justify-start items-center">
                        <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                            <FaAnglesLeft className='text-[10px]' />
                            Back
                        </Link>
                    </div>

                </section>

                <div className="flex flex-col justify-center items-center gap-[30px] px-[50px]">
                    {articles.length > 0 ?
                        articles.map((article, index) => (
                            <BlogArticleCard
                                key={index} {...article}
                                id={article.id}
                                coverImg={article.data.coverUrl}
                                title={article.data.title}
                                date={format(article.data.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                            />
                        ))
                        :
                        <div className="flex justify-center w-full font-agrandir text-[18px]">
                            <div className="flex flex-col">
                                Vous n'avez archivé aucun article !
                                <span className="h-1 w-10 bg-tangerine" />
                            </div>
                        </div>
                    }
                </div>

                <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
                    <Link to={"/dashboard/blogapp/new-article"} className='bg-black w-fit text-white font-bold py-2 px-3 rounded-md'>Nouvel Article</Link>
                    <button onClick={handleOpen} className='bg-black text-white font-bold py-2 px-3 rounded-md'>Nouvelle Catégorie</button>
                </div>

                <Modal isOpen={open} onClose={handleClose}>
                    {error && <div className='auth__error'>{error}</div>}
                    {showSuccess ? (
                        <center id='successmessage' className='font-agrandir'>
                            <p>Nouvelle rubrique ajoutée !</p>
                            <button type='button' className="btn-form" onClick={handleClose}>OK</button>
                        </center>
                    ) : (
                        <form onSubmit={addNewRubrique} name='addRubrique_form' className='font-agrandir' id='addRubriqueform'>
                            <div className='flex flex-col gap-5 justify-center items-center'>

                                <input
                                    type="text"
                                    className='outline-none border-2 px-4 py-2 rounded-md w-full'
                                    placeholder="Nom de la nouvelle catégorie"
                                    value={nom}
                                    onChange={e => setNom(e.target.value)}
                                    required />

                                <button type='submit' className="buttonviolet">
                                    {isLoading ? (
                                        <i className="fa fa-circle-o-notch fa-spin" />
                                    ) : (
                                        'Créer'
                                    )}
                                </button>

                            </div>
                        </form>
                    )}
                </Modal>

            </section>
        </div>
    )
}

export default ArchivedArticle