import React, { useEffect, useState } from 'react';
import Userline from './UserLine';
import { firestoreDatabase } from '../../../../utils/firebase';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link, useNavigate } from 'react-router-dom';
import { FaAnglesLeft } from 'react-icons/fa6';

const Users = () => {

    const [users, setUsers] = useState([]);

    //read users data from firestore
    useEffect(() => {
        const retrieveUsersData = async () => {
            const userColRef = query(collection(firestoreDatabase, 'users'), orderBy('createdAt', 'desc'))
            onSnapshot(userColRef, (snapshot) => {
                setUsers(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        }

        retrieveUsersData();
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Utilisateurs</p>


                <section className="flex pb-[30px]" id='blog'>

                    <div className="xl:flex hidden justify-start items-center">
                        <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                            <FaAnglesLeft className='text-[10px]' />
                            Back
                        </Link>
                    </div>

                </section>
                <div className="w-full flex justify-center px-[10px]">
                    <table className="w-full text-left">
                        <thead className='border-b-2 border-tangerine'>
                            <tr className='w-full flex justify-between'>
                                <th hidden={true}>id</th>
                                <th className='w-1/6'>Nom</th>
                                <th className='w-2/6'>Email</th>
                                <th className='w-1/6'>Rôle</th>
                                <th className='w-1/6'>Accès</th>
                                <th className='w-1/6'>Date de création</th>
                            </tr>
                        </thead>
                        <tbody>

                            {users.map((user) => {
                                return (
                                    <Userline
                                        id={user.id}
                                        key={user.id}
                                        displayName={user.data.displayName}
                                        email={user.data.email}
                                        role={user.data.role}
                                        autorisation={user.data.autorisation}
                                        creationDate={format(user.data.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                                    />
                                )
                            })}

                        </tbody>
                    </table>

                    <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
                        <Link to={'/dashboard/users/adduser'} className='bg-black text-white font-bold py-2 px-3 rounded-md'>Ajouter un membre</Link>
                    </div>

                </div>


            </section>

        </>
    )
}

export default Users