import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Ajoute un gestionnaire d'événement pour détecter le défilement de la page
        const handleScroll = () => {
            // Affiche le bouton lorsque la position de défilement est supérieure à 300 pixels
            const scrollTop = window.scrollY;
            setIsVisible(scrollTop > 300);
        };

        // Attache le gestionnaire d'événement au défilement de la page
        window.addEventListener('scroll', handleScroll);

        // Nettoie le gestionnaire d'événement lors du démontage du composant
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        // Fait défiler la page vers le haut
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Ajoute un effet de défilement en douceur
        });
    };

    return (
        <div>
            {/* Affiche le bouton uniquement lorsque isVisible est vrai */}
            {isVisible && (
                <button onClick={scrollToTop} className="scroll-to-top-button bg-aqua h-10 w-10 fixed right-[5vw] bottom-10 flex justify-center items-center">
                    <span className="cursor-pointer text-white">
                        <FaChevronUp />
                    </span>
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
