import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FaAnglesLeft } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { firestoreDatabase } from '../../../utils/firebase';
import WebEvent from '../../../components/webevent/WebEvent';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import JobOffer from './JobOffer';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const DevPro = () => {

    const navigate = useNavigate();

    const [formations, setFormations] = useState([]);
    const [jobOffers, setJobOffers] = useState([]);

    //read formations data from firebase
    useEffect(() => {
        const readFormationsData = async () => {
            const formationsRef = query(collection(firestoreDatabase, 'events'), orderBy('date', 'desc'), where('isVisible', '==', true), where('type', '==', 'formation'))
            onSnapshot(formationsRef, (snapshot) => {
                setFormations(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readFormationsData();
    }, []);


    //read jobs data from firebase
    useEffect(() => {
        const readJobOffersData = async () => {
            const jobsRef = query(collection(firestoreDatabase, 'jobs'), orderBy('createdAt', 'desc'), where('isVisible', '==', true), where('type', '==', 'Rémunéré'))
            onSnapshot(jobsRef, (snapshot) => {
                setJobOffers(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readJobOffersData();
    }, []);

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[20px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='prodev'></div>

            <section className="flex flex-col xl:gap-[50px]">

                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-tangerine slide-left">
                        <p className="font-pressura xl:text-[64px] text-[30px] xl:text-white text-tangerine xl:px-[60px] px-5 xl:w-[1440px] slide-left">CARRIERE</p>
                    </div>
                </motion.div>

                <div className="xl:flex hidden xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-tangerine text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className="w-full grid justify-center xl:gap-[100px] gap-[20px] xl:pb-[100px]">

                <section className="flex flex-col gap-[30px] max-w-[1440px]" id='certifications'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Certifications</h1>

                    <div className="xl:px-[100px] px-[20px] text-justify xl:text-[16px] text-[12px] flex flex-col justify-center items-center gap-2.5 font-agrandir xl:max-w-[1440px]">
                        <p className="xl:w-[800px] w-full">
                            Le PMI propose des certifications en gestion de projets pour vous aider à progresser à chaque étape de votre carrière. Nos certifications vous permettent de travailler dans n'importe quel secteur d'activité à travers le monde et avec n'importe quelle méthodologie de gestion de projets.
                            <br /> <br />
                            Elles s'appuient sur des normes rigoureuses et des recherches permanentes pour répondre aux exigences des projets et des organisations du monde réel. Quels que soient vos objectifs professionnels, nous avons une certification qui vous aidera à les atteindre.
                        </p>
                    </div>

                    <div className="w-full flex justify-center items-center mt-5">
                        <Link to="https://www.pmi.org/certifications" target="_blank" rel="noopener" className="buttonviolet gap-2">
                            Certifications PMI
                            <FaAngleDoubleRight className='flex text-[14px]' />
                        </Link>
                    </div>

                </section>

                <section className="flex flex-col gap-[30px] max-w-[1440px]" id='formations'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Formations</h1>

                    <div className="flex flex-col justify-center xl:items-center gap-[30px] xl:px-[160px] px-[20px]">

                        {formations.length > 0 ?
                            formations.map((event, index) => (
                                <WebEvent key={index} {...event}
                                    id={event.id}
                                    coverImg={event.data.coverUrl}
                                    title={event.data.eventName}
                                    date={format(parseISO(event.data.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                                    link={event.data.eventLinkOrPlace} />
                            ))
                            :
                            <div className="flex justify-center xl:w-[800px] w-full font-agrandir xl:text-[18px] text-[15px] xl:pb-[100px] pb-[80px]">
                                <div className="flex flex-col">
                                    Aucune formation à venir !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>

                </section>

                <section className="flex flex-col gap-[30px] max-w-[1440px]" id='jobs'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Offres d'emploi</h1>

                    <div className="flex flex-col justify-center xl:items-center gap-[30px] xl:px-[160px] px-[20px]">

                        {jobOffers.length > 0 ?
                            jobOffers.map((job, index) => (
                                <JobOffer key={index} {...job}
                                    id={job.id}
                                    jobtitle={job.data.jobtitle}
                                />
                            ))
                            :
                            <div className="flex justify-center xl:w-[800px] w-full font-agrandir xl:text-[18px] text-[15px] xl:pb-[100px] pb-[80px]">
                                <div className="flex flex-col">
                                    Aucune offre d'emploi disponible !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>
                </section>

            </div>

        </div>
    )
}

export default DevPro