import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const JobOffer = ({ id, jobtitle }) => {

  const [ref, inView] = useInView({
    triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
  });


  return (
    <motion.div
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 },
      }}
      transition={{ type: "linear", damping: 6, bounce: 0.6, duration: 0.9 }}
    >
      <div className='flex justify-between items-center xl:px-10 xl:py-5 p-2.5 xl:w-[800px] w-full rounded-md border-2 hover:border-lightgrey bg-lightgrey hover:bg-white'>
        <p className="text-agrandir text-black">{jobtitle}</p>

        <Link to={"/devpro/jobdetail/" + id + "#jobdetails"} className='buttonblack'>Voir l'offre</Link>
      </div>
    </motion.div>
  )
}

export default JobOffer