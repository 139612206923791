import React, { useEffect, useState } from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { auth, firestoreDatabase } from '../../../utils/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const Board = () => {

  const user = auth.currentUser;  //Récupérer les données de l'utilisateur
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const q = query(collection(firestoreDatabase, "users"),
        where("email", "==", user.email))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserRole(doc.data()?.role);
      })
    };
    fetchUserData();
  }, [user]);

  const [docs, setDocs] = useState(null);
  const [articlesCount, setArticlesCount] = useState(null);
  const [events, setEvents] = useState(null);
  const [users, setUsers] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(null);

  useEffect(() => {
    //docs
    const m = query(collection(firestoreDatabase, "docs"));
    getDocs(m)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setDocs(count);
      })
      .catch((error) => {
      });

    //articles
    const a = query(collection(firestoreDatabase, "articles"), where('isVisible', '==', true));
    getDocs(a)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setArticlesCount(count);
      })
      .catch((error) => {
      });

    //events
    const c = query(collection(firestoreDatabase, "events"), where('isVisible', '==', true));
    getDocs(c)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setEvents(count);
      })
      .catch((error) => {
      });

    //messages
    const u = query(collection(firestoreDatabase, "inbox"), where('read', '==', false));
    getDocs(u)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setUnreadMessages(count);
      })
      .catch((error) => {
      });

    //users
    const k = query(collection(firestoreDatabase, "users"));
    getDocs(k)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setUsers(count);
      })
      .catch((error) => {
      });

    //jobs
    const r = query(collection(firestoreDatabase, "jobs"), where('isVisible', '==', true));
    getDocs(r)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setJobs(count);
      })
      .catch((error) => {
      });

  }, []);

  return (
    <div className='flex items-center gap-20 w-full p-[60px]'>

      <section className="flex flex-col gap-10 font-bold font-agrandir">

        <Link
          to={"/dashboard/blogapp"}
          className='flex justify-between items-center bg-aqua text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
          Blog
          <FaAngleDoubleRight />
        </Link>

        <Link to={"/dashboard/eventapp"}
          className='flex justify-between items-center bg-black text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
          Evènements
          <FaAngleDoubleRight />
        </Link>

        <Link to={"/dashboard/inbox"}
          className='flex justify-between items-center bg-violet text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
          Messagerie
          <FaAngleDoubleRight />
        </Link>

        <Link to={"/dashboard/docs"}
          className='flex justify-between items-center bg-tangerine text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
          Documentation
          <FaAngleDoubleRight />
        </Link>

        {userRole === 'Administrateur' &&
          <Link to={"/dashboard/users"}
            className='flex justify-between items-center bg-black text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
            Utilisateurs
            <FaAngleDoubleRight />
          </Link>
        }

        <Link to={"/dashboard/jobs"}
          className='flex justify-between items-center bg-tangerine text-white gap-[10px] p-4 cursor-pointer text-[14px] rounded w-[200px] hover:w-[105%]'>
          Offres d'emploi
          <FaAngleDoubleRight />
        </Link>

      </section>

      <section className="grid grid-cols-3 gap-5 text-white font-agrandir">

        <Link to={'/dashboard/blogapp'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-aqua rounded-lg shadow-md' id='articles_count'>
          {articlesCount !== null ? (
            <p className='text-[32px] font-bold'>{articlesCount}</p>
          ) : (
            <p className='text-[32px] font-bold'>...</p>
          )}
          <p className='text-[18px]'>Article.s publié.s</p>
        </Link>

        <Link to={'/dashboard/eventapp'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-black rounded-lg shadow-md' id='collections_count'>
          {events !== null ? (
            <p className='text-[32px] font-bold'>{events}</p>
          ) : (
            <p className='text-[32px] font-bold'>...</p>
          )}
          <p className='text-[18px]'>Evènement.s</p>
        </Link>

        <Link to={'/dashboard/inbox'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-violet rounded-lg shadow-md' id='rubriques_count'>
          {unreadMessages !== null ? (
            <p className='text-[32px] font-bold'>{unreadMessages}</p>
          ) : (
            <p className='text-[32px] font-bold'>...</p>
          )}
          <p className='text-[18px]'>Message.s non lu.s</p>
        </Link>

        <Link to={'/dashboard/docs'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-tangerine rounded-lg shadow-md' id='members_count'>
          {docs !== null ? (
            <p className='text-[32px] font-bold'>{docs}</p>
          ) : (
            <p className='text-[32px] font-bold'>...</p>
          )}
          <p className='text-[18px]'>Document.s</p>
        </Link>

        {userRole === 'Administrateur' &&
          <Link to={'/dashboard/users'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-black rounded-lg shadow-md' id='collections_count'>
            {users !== null ? (
              <p className='text-[32px] font-bold'>{users}</p>
            ) : (
              <p className='text-[32px] font-bold'>...</p>
            )}
            <p className='text-[18px]'>Utilisateurs.s</p>
          </Link>
        }

        <Link to={'/dashboard/jobs'} className='flex flex-col justify-center items-center p-[30px] gap-5 bg-tangerine rounded-lg shadow-md' id='rubriques_count'>
          {jobs !== null ? (
            <p className='text-[32px] font-bold'>{jobs}</p>
          ) : (
            <p className='text-[32px] font-bold'>...</p>
          )}
          <p className='text-[18px]'>Offre.s d'emploi</p>
        </Link>

      </section>

    </div>
  )
}

export default Board