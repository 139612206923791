import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../../utils/firebase';
import { fr } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
import { FaAnglesLeft } from 'react-icons/fa6';
import { FaLink } from 'react-icons/fa';

const Event = () => {

    const { id } = useParams();
    const [event, setevent] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const readThiseventData = async () => {
            const docRef = doc(firestoreDatabase, 'events', id);
            const docSnap = await getDoc(docRef);
            setevent(docSnap.data());
        };
        readThiseventData();
    }, [id]);

    return (
        <div className="grid justify-center w-full">

            <div className="xl:h-[140px] xl:block hidden" id='thisevent'></div>

            <div className="flex flex-col gap-5 w-full pt-5 xl:pb-[100px] pb-[80px] max-w-[1440px]">

                <div className="flex justify-start items-center xl:px-[160px] px-[20px]" id=''>
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-black text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

                <section className="flex flex-col items-center gap-5">

                    <div className="w-full flex flex-col xl:gap-16 gap-5 rounded-lg eventscroll px-[20px] xl:px-[160px]">

                        <div className="flex xl:flex-row flex-col items-center justify-start xl:gap-10 gap-10">
                            <img src={event.coverUrl} alt="" className='w-auto object-fill h-[auto] max-h-[400px] rounded-xl shadow-xl' />

                            <div className="flex flex-col">

                                <p className="font-pressura text-violet xl:text-[36px] uppercase text-[18px]">{event.eventName}</p>

                                <div className="flex xl:flex-row flex-col xl:gap-0 gap-5 justify-between xl:items-center">
                                    <div className="flex flex-col g-2.5 font-pressura">
                                        <p className="">Date: {event.date && format(parseISO(event.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}</p>
                                        {event.type === 'presential' ?
                                            <div className="flex items-center gap-[5px] font-pressura">
                                                Lieu:
                                                <a href={event.eventMapLink} target="_blank" rel="noopener" className="text-blue-400 w-fit cursor-pointer overflow-hidden text-ellipsis">{event.eventLinkOrPlace}</a>
                                            </div>
                                            :
                                            <div className="flex items-center gap-[5px] xl:text-[16px] text-[14px] font-pressura w-full">
                                                Lien de participation:
                                                <a href={event.eventLinkOrPlace} target="_blank" rel="noopener" className="flex items-center gap-[5px] text-blue-400 w-fit h-5 cursor-pointer">
                                                    Cliquez ici <FaLink className='text-[12px]' />
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>

                        <p className="font-agrandir text-black text-[14px] text-justify" dangerouslySetInnerHTML={{ __html: event?.content }} />

                    </div>

                </section>

            </div>

        </div>
    )
}

export default Event