import React, { useEffect, useState } from 'react';
import memberCoverImg from '../../assets/images/members/banner.jpg';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../utils/firebase';
import PmiTogoData from '../../components/pmi-togo-data/PmiTogoData';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import avOne from '../../assets/images/members/av_one.jpg';
import avTwo from '../../assets/images/members/av_two.jpg';
import avThree from '../../assets/images/members/av_three.jpg';
import avFour from '../../assets/images/members/av_four.jpg';
import avFive from '../../assets/images/members/av_five.jpg';
import avSix from '../../assets/images/members/av_six.jpg';
import VolunteershipOffer from '../../components/volunteership-offer/VolunteershipOffer';


const Members = () => {

    const Advantages = [
        {
            title: 'Accès à un réseau professionnel régional et mondial de praticiens du management de projet',
            image: avOne,
            content: 'Notre réseau offre d\'innombrables possibilités de rencontrer et de collaborer avec des experts, des pairs, des mentors et des recruteurs du monde entier. Ces liens professionnels peuvent ouvrir des portes vers de nouvelles collaborations, des projets nationaux et internationaux.'
        },
        {
            title: 'Accès à des ressources, publications et outils liés à la gestion de projets',
            image: avTwo,
            content: 'L\'appartenance au PMI Togo Chapter vous donne un accès privilégié à une multitude de ressources et de publications spécialisées dans le domaine de la gestion de projets. Vous pouvez accéder à des études de cas, des articles de pointe, des bonnes pratiques et des outils de gestion de projets qui vous permettront de rester à la pointe des dernières tendances et de développer vos compétences de manière continue.'
        },
        {
            title: 'Reconnaissance professionnelle grâce aux certifications PMI',
            image: avThree,
            content: 'En rejoignant le PMI, vous avez l\'opportunité de vous démarquer professionnellement grâce aux certifications reconnues mondialement. Ces certifications témoignent de vos compétences, ce qui vous permettra d\'accéder à des opportunités professionnelles mieux rémunérées et plus intéressantes.'
        },
        {
            title: 'Développement professionnel continu',
            image: avFour,
            content: 'Des programmes de formation continue et des opportunités de perfectionnement sont proposés régulièrement pour vous aider à rester compétent dans votre domaine et à faire évoluer votre carrière.'
        },
        {
            title: 'Accès à des opportunités professionnelles via la plateforme "Career Central',
            image: avFive,
            content: '"Career Central" vous permet de rechercher des emplois spécifiquement liés à la gestion de projets, de publier votre cv pour attirer les recruteurs et d\'être informé des offres d\'emploi pertinentes dans votre domaine.'
        },
        {
            title: 'Opportunités de collaboration et de réseautage au travers des conférences au niveau national,régional et international',
            image: avSix,
            content: 'Des conférences et des événements nationaux, régionaux et internationaux sont régulièrement organisés par le PMI. Ces occasions uniques vous permettent de rencontrer des experts, d\'échanger des idées, de présenter vos projets et d\'élargir vos connaissances et votre réseau'
        },

    ];

    const [volunteering, setVolunteering] = useState([]);

    //read jobs data from firebase
    useEffect(() => {
        const readJobOffersData = async () => {
            const jobsRef = query(collection(firestoreDatabase, 'jobs'), orderBy('createdAt', 'desc'), where('isVisible', '==', true), where('type', '==', 'Volontariat'))
            onSnapshot(jobsRef, (snapshot) => {
                setVolunteering(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        readJobOffersData();
    }, []);

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    const [adRef, inAdView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[50px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='member'></div>

            <section className="flex flex-col gap-[30px]">

                <div className="w-full relative xl:h-[550px] h-[80vh] xl:px-[100px] px-[20px] flex justify-center items-end xl:pb-10 pb-5 bg-no-repeat bg-cover bg-top"
                    style={{ backgroundImage: `url(${memberCoverImg})` }}>
                    <div className="flex justify-start items-center w-full max-w-[1440px]">
                        <motion.div
                            ref={ref}
                            animate={inView ? 'visible' : 'hidden'}
                            variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 150 },
                            }}
                            transition={{ type: "spring", damping: 4, bounce: 0.6, duration: 0.9 }}
                        >
                            <div className="bg-[#553192E5] flex flex-col gap-5 py-[20px] px-[15px] xl:w-[510px] max-w-[420px] rounded-md shadow-md slide-up">
                                <p className="font-pressura text-white text-[16px]">Accepter notre invitation et faites partie d’une grande communauté !</p>

                                <p className="font-agrandir text-white text-[14px]">En devenant membre du PMI, vous n'obtenez pas seulement des réductions sur les certifications et les événements ; vous rejoignez une communauté mondiale qui s'épanouit dans la connexion, le développement et le bien social.</p>

                                <a href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter" target="_blank" rel="noopener" className="buttonwhite">Rejoignez-nous maintenant</a>
                            </div>
                        </motion.div>
                    </div>
                </div>

            </section>

            <div className="flex flex-col w-full justify-center xl:items-center xl:gap-[100px] gap-[50px]">
                <section className="flex flex-col gap-[30px] xl:w-[1320px] max-w-[1440px]" id='advantages'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-5">Avantages</h1>

                    <div className="flex flex-col justify-center items-center gap-[50px]">

                        <div className="flex flex-col xl:gap-20 gap-10 border-grey xl:w-[70%] w-[90%]">

                            {Advantages.map((advantage, idx) => (
                                <motion.div
                                    ref={adRef}
                                    animate={inAdView ? 'visible' : 'hidden'}
                                    initial='hidden'
                                    whileInView='visible'
                                    variants={{
                                        visible: { opacity: 1, x: 0 },
                                        hidden: { opacity: 0, x: idx % 2 === 0 ? -100 : 100 },
                                    }}
                                    transition={{ type: "linear", damping: 5, bounce: 0.6, duration: 0.9 }}
                                >
                                    <div key={idx} className={`flex flex-col gap-5 items-center ${idx % 2 === 0 ? 'xl:flex-row' : 'xl:flex-row-reverse'}`}>
                                        <img src={advantage.image} className='xl:w-1/3 rounded-md' alt="" />
                                        <div className="flex flex-col xl:gap-2.5">
                                            <p className='text-violet xl:text-[17px] font-agrandir'>{advantage.title}</p>
                                            <p className='font-agrandir'>{advantage.content}</p>
                                        </div>
                                    </div>
                                </motion.div>
                            ))}

                        </div>

                        <div className="flex justify-center items-center">
                            <a href="https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter" target="_blank" rel="noopener" className='buttonviolet'>Rejoindre PMI TOGO</a>
                        </div>

                    </div>

                </section>

                <PmiTogoData />

                <section className="flex flex-col gap-[30px] xl:w-[1320px] max-w-[1440px]" id='volunteering'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-5">Volontariat</h1>

                    <div className="flex flex-col justify-center xl:items-center gap-[30px] xl:px-[160px] px-[20px]">

                        {volunteering.length > 0 ?
                            volunteering.map((job, index) => (
                                <VolunteershipOffer key={index} {...job}
                                    id={job.id}
                                    jobtitle={job.data.jobtitle}
                                    coverImg={job.data.jobCover}
                                />
                            ))
                            :
                            <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                                <div className="flex flex-col">
                                    Aucune offre de volontariat disponible !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>

                </section>
            </div>

        </div>
    )
}

export default Members