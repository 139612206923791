import React, { useEffect, useState } from 'react'
import DocLine from '../../components/docline/DocLine'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { firestoreDatabase } from '../../utils/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { FaAnglesLeft } from 'react-icons/fa6';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


const Documentation = () => {

    const [docs, setDocs] = useState([]);

    //read articles data from firebase
    useEffect(() => {
        const fetchDocs = async () => {
            const docsRef = query(collection(firestoreDatabase, 'docs'), orderBy('createdAt', 'desc'))
            onSnapshot(docsRef, (snapshot) => {
                setDocs(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        fetchDocs();
    }, []);

    const navigate = useNavigate();

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[20px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='docs'></div>
            
            <section className="flex flex-col xl:gap-[50px]" id=''>
                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-tangerine slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-tangerine xl:px-[60px] px-5 xl:w-[1440px] slide-left">DOCUMENTATION</p>
                    </div>
                </motion.div>
                <div className="xl:flex hidden xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-tangerine text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className="flex w-full justify-center">
                <section className='flex flex-col gap-[30px] w-full xl:min-w-[1280px] max-w-[1440px]'>
                    <div className="flex flex-col justify-center items-center ">

                        {docs.length > 0 ?
                            docs.map((doc, index) => (
                                <div key={index} className="flex flex-col border-t-2 border-grey xl:w-[60%] w-[90%] font-pressura">
                                    <DocLine
                                        {...doc}
                                        id={doc.id}
                                        title={doc.data.docName}
                                        doc={doc.data.fileUrl}
                                    />
                                </div>
                            ))
                            :
                            <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                                <div className="flex flex-col">
                                    Aucun document disponible !
                                    <span className="h-1 w-10 bg-tangerine" />
                                </div>
                            </div>
                        }

                    </div>
                </section>

            </div>
        </div>
    )
}

export default Documentation