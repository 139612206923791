import React, { useEffect } from 'react'
import logo from "../../assets/logo/pmi_chp_logo_TOGO_hrz_rgb.png";
import { Link, useLocation } from 'react-router-dom';
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdOutlineLocalPhone, MdOutlineMailOutline } from 'react-icons/md';


const Footer = () => {

  const location = useLocation();

  useEffect(() => {
    // Faire défiler vers l'élément avec l'ID correspondant au hachage dans l'URL
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (

    <footer className="bg-lightgrey flex flex-col items-center pt-[30px] md:gap-20 gap-14">

      {/* Web footer */}
      <div className="xl:flex hidden xl:gap-[80px] justify-between gap-5 xl:px-0 px-[10px] h-[220px] max-w-[1440px]">
        <div className="xl:block hidden"></div>
        <div className="flex flex-col gap-[30px]">
          <Link to={'/#hero'}>
            <img src={logo} className='xl:h-[80px] h-[60px]' alt="PMI Chapter Togo" />
          </Link>
          <div className="flex flex-col gap-2.5 text-black">

            <a href="mailto:pmitogo@pmi-togo.org" className="flex items-center gap-[5px] text-[14px] font-agrandir hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">
              <MdOutlineMailOutline />
              <p className="">pmitogo@pmi-togo.org</p>
            </a>

            <a href="tel:+22890857070" className="flex items-center gap-[5px] text-[14px] font-agrandir hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">
              <MdOutlineLocalPhone />
              <p className="">00228 90 85 70 70</p>
            </a>

          </div>
        </div>

        <div className="flex flex-col gap-[15px]">

          <p className="font-bold text-pressura text-[15px] text-black">Qui Sommes-nous ?</p>

          <Link to="/we#we" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Présentation - PMI TOGO</Link>

          <Link to="/we#headmember" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Membres dirigeants</Link>

          <Link to="/events#evenements" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Evènements</Link>

          <Link to="/blog#blog" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Publications</Link>

          <Link to="/devpro#prodev" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Carrières</Link>

        </div>

        <div className="flex flex-col gap-[15px]">

          <p className="font-bold text-pressura text-[15px] text-black">PMI TOGO CHAPTER</p>

          <Link to="/#hero" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Accueil</Link>

          <Link to="/privacy-policy#policy" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Mentions légales & <br /> politique de confidentialité</Link>

          <Link to="/site-map#plan" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Plan du site</Link>

          <Link to="/contact#contact" className="text-agrandir text-[14px] text-black hover:border-b-2 hover:border-tangerine hover:pb-2 transition-one">Contact</Link>

        </div>

        <div className="flex flex-col gap-[15px]">

          <p className="flex flex-col font-bold text-pressura text-[15px] text-black">
            Suivez-nous sur :
            <span className="h-1 w-10 bg-tangerine" />
          </p>

          <div className="flex gap-5">
            <a href="https://www.linkedin.com/company/pmi-togo-chapter/" target="_blank" rel="noopener" className='text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one' >
              <FaLinkedinIn />
            </a>

            <a href="https://twitter.com/pmitogochapter" target="_blank" rel="noopener" className="text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one">
              <BsTwitterX />
            </a>

            <a href="https://www.facebook.com/pmitogochapter" target="_blank" rel="noopener" className='text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one' >
              <FaFacebookF />
            </a>

            <a href="https://www.instagram.com/pmi_togo/" target="_blank" rel="noopener" className='text-2xl hover:border-b-2 hover:border-tangerine pb-2.5 transition-one' >
              <FaInstagram />
            </a>
          </div>
        </div>
        <div className="xl:block hidden"></div>
      </div>

      {/* Mobile footer */}
      <div className="xl:hidden flex flex-col gap-10 w-full">

        <div className="flex flex-col w-full justify-start px-5 gap-2.5">
          <p className="flex flex-col font-bold">
            Qui Sommes-nous ?
            <span className="h-1 w-5 bg-violet" />
          </p>

          <div className="flex flex-col gap-2">
            <Link to="/we#we" className="text-agrandir text-[14px] text-black">Présentation - PMI TOGO</Link>

            <Link to="/members#member" className="text-agrandir text-[14px] text-black">Membres</Link>

            <Link to="/events#evenements" className="text-agrandir text-[14px] text-black">Evènements</Link>

            <Link to="/blog#blog" className="text-agrandir text-[14px] text-black">Publications</Link>

            <Link to="/contact#contact" className="text-agrandir text-[14px] text-black">Contact</Link>
          </div>

        </div>


        <div className="flex flex-col w-full justify-start px-5 gap-2.5">
          <p className="flex flex-col font-bold">
            Liens
            <span className="h-1 w-5 bg-tangerine" />
          </p>

          <div className="flex flex-col gap-2">
            <Link to="/devpro#prodev" className="text-agrandir text-[14px] text-black">Carrières</Link>

            <Link to="/privacy-policy#policy" className="text-agrandir text-[14px] text-black">Mentions légales & <br /> politique de confidentialité</Link>
          </div>

        </div>

        <div className="flex flex-col items-center gap-5">
          <img src={logo} className='xl:h-[80px] h-[50px]' alt="PMI Chapter Togo" />

          <div className="flex flex-col gap-2.5 text-black">

            <a href="mailto:pmitogo@pmi-togo.org" className="flex items-center gap-[5px] text-[14px] font-agrandir">
              <MdOutlineMailOutline />
              <p className="">pmitogo@pmi-togo.org</p>
            </a>

            <a href="tel:+22890857070" className="flex items-center gap-[5px] text-[14px] font-agrandir">
              <MdOutlineLocalPhone />
              <p className="">00228 90 85 70 70</p>
            </a>

          </div>

          <div className="flex gap-5">
            <a href="https://www.linkedin.com/company/pmi-togo-chapter/" target="_blank" rel="noopener" className='text-xl' >
              <FaLinkedinIn />
            </a>

            <a href="https://twitter.com/pmitogochapter" target="_blank" rel="noopener" className="text-xl">
              <BsTwitterX />
            </a>

            <a href="https://www.facebook.com/pmitogochapter" target="_blank" rel="noopener" className='text-xl' >
              <FaFacebookF />
            </a>

            <a href="https://www.instagram.com/pmi_togo/" target="_blank" rel="noopener" className='text-xl' >
              <FaInstagram />
            </a>
          </div>
        </div>

      </div>

      <div className="w-full grid justify-center">
        <div className="border-t-2 xl:min-w-[920px] xl:max-w-[1440px] xl:mx-[60px] mx-5">
          <p className="text-grey w-full xl:text-[14px] text-[10px] xl:py-[30px] py-[10px] xl:text-start text-center">©2023 PMI Chapitre Togo . Tous droits réservés.</p>
        </div>
      </div>

    </footer>

  )
}

export default Footer