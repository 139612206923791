import { Timestamp, doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { firestoreDatabase } from '../../../../utils/firebase';
import ToggleSwitch from '../../../../components/toggleswitch/ToggleSwitch';

const Userline = ({ id, displayName, email, role, autorisation, creationDate }) => {

  const SwitchMemberAccess = async (e) => {
    e.preventDefault()
    const userDocRef = doc(firestoreDatabase, 'users', id)
    try {
        await updateDoc(userDocRef, {
            autorisation: !autorisation,
            lastModif: Timestamp.now()
        })
    } catch (error) {
        alert(error)
    }
}
  return (
    <tr className='flex justify-between w-full text-left py-2 border-b-2'>
      <td hidden={true}>{`${id}`}</td>
      <td className='w-1/6'>{displayName}</td>
      <td className='w-2/6'>{email}</td>
      <td className='w-1/6'>{role}</td>
      <td className='flex items-center w-1/6'>
        <ToggleSwitch switchFunction={SwitchMemberAccess} SwitchState={autorisation} />
      </td>
      <td className='w-1/6'>{creationDate}</td>
    </tr>)
}

export default Userline