import React, { useEffect, useState } from 'react';
import './newevent.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Link, useNavigate } from 'react-router-dom';
import { BsCheck2Circle } from 'react-icons/bs';
import { auth, firestoreDatabase, storage } from '../../../../utils/firebase';
import Modal from '../../../../components/modal/Modal';
import { FaAnglesLeft } from 'react-icons/fa6';

const quillModules = {
    toolbar: [
        [{ 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

function NewEvent() {

    const [eventName, setEventName] = useState('');
    const [date, setDate] = useState('');
    const [eventLinkOrPlace, setEventLinkOrPlace] = useState('');
    const [eventMapLink, setEventMapLink] = useState('');
    const [cover, setCover] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');

    const user = auth.currentUser;
    //Récupérer les données de l'utilisateur
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const q = query(collection(firestoreDatabase, "users"),
                where("email", "==", user.email))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setUserData(doc.data());
            })
        };
        fetchUserData();
    }, [user]);


    const handleCover = (event) => {
        setCover(event.target.files[0]);
    };
    const handleChange = (newValue) => {
        setContent(newValue);
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        navigate('/dashboard/');
    }

    const [isLoading, setIsLoading] = useState(false);

    const addNewEvent = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true); // Afficher le loader

            // Télécharger la photo de profil sur Firebase Storage
            const fileRef = ref(storage, `events_covers/${cover.name}`);
            await uploadBytes(fileRef, cover);

            // Récupérer l'URL de téléchargement de la photo de profil
            const coverUrl = await getDownloadURL(fileRef);

            // Adding the article to Firebase Firestore
            await addDoc(collection(firestoreDatabase, "events"), {
                eventName: eventName,
                date: date,
                eventLinkOrPlace: eventLinkOrPlace,
                eventMapLink: eventMapLink,
                coverUrl: coverUrl,
                type: type,
                content: content,
                author: userData?.displayName,
                isVisible: true,
                createdAt: new Date(),
            });

            setIsLoading(false);

            setOpen(true)

            setEventName('');
            setCover('');
            setDate('');
            setEventLinkOrPlace('');
            setType('');
            setContent('');

        } catch (error) {
            setIsLoading(false);
            console.error("Error adding article: ", error);
        }
    };

    const navigate = useNavigate();

    return (

        <div id='newArticle' className='px-[60px]'>

            <section className="flex pb-[30px]" id='blog'>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className='flex justify-center w-full'>

                <form className='font-agrandir w-full' onSubmit={addNewEvent}>

                    <div className='form-ctrl'>
                        <label><p className=''>Type d'évènement</p></label>
                        <select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required>
                            <option >-Sélectionner le type de votre évènement-</option>
                            <option value="online">Webinaire</option>
                            <option value="presential">Présentiel</option>
                            <option value="formation">Formation</option>

                        </select>
                    </div>

                    <div className='form-ctrl'>
                        <label><p>Nom de l'évènement</p></label>
                        <input type="text"
                            className='outline-none border-2 px-4 py-2 rounded-md w-full'
                            value={eventName}
                            onChange={(event) => setEventName(event.target.value)}
                            required
                        />
                    </div>

                    <div className='form-ctrl'>
                        <label><p>Date de l'évènement</p></label>
                        <input type="datetime-local"
                            className='outline-none border-2 px-4 py-2 rounded-md w-full'
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            required
                        />
                    </div>

                    <div className='form-ctrl'>
                        <label><p>{type === 'presential' ? 'Lieu de l\'événement' : 'Lien de participation'}</p></label>
                        <input type="text"
                            className='outline-none border-2 px-4 py-2 rounded-md w-full'
                            value={eventLinkOrPlace}
                            onChange={(event) => setEventLinkOrPlace(event.target.value)}
                            required
                        />
                    </div>

                    {type === 'presential' &&
                        <div className='form-ctrl'>
                            <label><p>Adresse Google Maps du lieu de l'évènement</p></label>
                            <input type="text"
                                className='outline-none border-2 px-4 py-2 rounded-md w-full'
                                value={eventMapLink}
                                onChange={(event) => setEventMapLink(event.target.value)}
                            />
                        </div>
                    }

                    <div className='form-ctrl'>
                        <label><p className=''>Photo de couverture de l'évènement</p></label>
                        <input type="file"
                            onChange={handleCover}
                            required
                        />
                    </div>

                    <div className='form-ctrl'>
                        <label>Description de l'évènement</label>
                        <ReactQuill
                            value={content}
                            onChange={handleChange}
                            modules={quillModules}
                            formats={quillFormats}
                        />
                    </div>

                    <div className='btn-ctrl'>
                        <button className="buttonviolet w-[200px!important]">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <p>Publier l'évènement</p>
                            )}
                        </button>
                    </div>

                </form>

            </div>

            {open &&
                <Modal isOpen={open} onClose={handleClose}>
                    <center>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            <BsCheck2Circle className='text-green-700 text-[50px]' />

                            <p>Votre évènement a bien été publié !</p>

                            <button
                                className='buttonviolet'
                                type='submit'
                                onClick={handleClose}
                            >OK</button>

                        </div>
                    </center>
                </Modal>
            }

        </div>

    )
}

export default NewEvent