import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import grwtSymbolTangerine from '../../assets/pmi/aqua/pmi_sym_grwt_aqua_rgb.png';
import { IoMdCalendar } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";

//slider imgs
import slide1 from "../../assets/images/focused-workaholic-african-american-businesswoman-working-company-financial-charts-presentation.jpg";
import slide2 from "../../assets/images/d.jpg";
import slide3 from "../../assets/images/pexels-christina-morillo-1181422.jpg";
import slide4 from "../../assets/images/lancement-2.png";

import { Link } from 'react-router-dom';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../utils/firebase';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FaAngleDoubleRight, FaLink } from 'react-icons/fa';
import PmiTogoData from '../../components/pmi-togo-data/PmiTogoData';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import lecomomiste from "../../assets/images/leconomiste-logo.png";
import pbs from "../../assets/images/pbs-logo.jpg";
import togocom from "../../assets/images/togocom-logo.png";
import inovact from "../../assets/images/inovact-logo.png";
import neurotech from "../../assets/images/neurotech-logo.png";
import cfao from "../../assets/images/cfao-logo.png";
import aufLogo from "../../assets/images/partners/auf_logo.png";
import gold from "../../assets/images/gold-sponsor.png";
import silver from "../../assets/images/silver-sponsor.png";

const Home = () => {

    const sliderImg = [
        {
            src: slide4,
            text: 'Slide Four'
        },
        {
            src: slide1,
            text: 'Slide One'
        },
        {
            src: slide2,
            text: 'Slide Two'
        },
        {
            src: slide3,
            text: 'Slide Three'
        },
    ]

    const [lastArticles, setLastArticles] = useState([]);
    const [nextEvent, setNextEvent] = useState([]);

    useEffect(() => {
        //read last articles data from firebase
        const readLastArticleData = async () => {
            const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', true), limit(3))
            onSnapshot(articlesRef, (snapshot) => {
                setLastArticles(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };

        //read next event data from firebase
        const readNextEventData = async () => {
            const currentDate = new Date().toISOString();

            const nextEventRef = query(collection(firestoreDatabase, 'events'), orderBy('date', 'asc'), where('isVisible', '==', true), where('date', '>=', currentDate), limit(3))
            onSnapshot(nextEventRef, (snapshot) => {
                setNextEvent(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };

        readNextEventData();

        readLastArticleData();

    }, []);

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[50px] xl:pb-[60px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='hero'></div>

            <section className="flex justify-center items-center">
                <div className="w-full xl:min-h-[80vh] min-h-[90vh] xl:mt-[2px] flex items-center justify-between cursor-pointer 2xl:max-w-[1980px]">
                    <Slider
                        {...{
                            dots: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: true,
                            pauseOnHover: false,
                            focusOnSelect: true,
                            arrows: false,
                            className: 'w-[100%]',
                            useCSS: true,
                            fade: true,
                        }}
                    >
                        {sliderImg.map((img, idx) => (
                            <div className="w-[100%] xl:min-h-[80vh] xl:h-[80vh] h-[90vh]" key={idx}>
                                <div className="h-full w-full bg-center bg-cover flex justify-between items-end xl:px-[60px] px-5" style={{ backgroundImage: `url(${img.src})` }}>

                                    <div class="xl:text-[32px] text-[18px] xl:pb-[80px] pb-[150px] heading flex flex-col font-agrandir font-bold text-white">
                                        <div>
                                            <p class="slide-up drop-shadow-xl shadow-black">Bienvenue au Chapitre Togolais du</p>
                                        </div>
                                        <div>
                                            <p class="slide-up drop-shadow-xl shadow-black"><span class="text-tangerine">Project Management Institute <span className='text-white'>(PMI)</span></span> ,</p>
                                        </div>
                                        <div>
                                            <p class="slide-up drop-shadow-xl shadow-black">Votre passerelle vers l'excellence dans</p>
                                        </div>
                                        <div>
                                            <p class="slide-up drop-shadow-xl shadow-black">la gestion de projets au cœur du <span className="text-tangerine"> Togo </span>!</p>
                                        </div>
                                    </div>

                                    <div className="xl:block hidden">
                                        <img src={grwtSymbolTangerine} className='xl:w-[60px] xl:mr-10 w-10' alt="" />
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

            </section>

            <div className="xl:grid contents justify-center items-center xl:gap-[100px] gap-[50px] xl:w-full">

                <section className="flex flex-col gap-[30px] xl:px-0 px-[20px] max-w-[1440px]">
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px]">Événement à venir</h1>
                    <motion.div
                        ref={ref}
                        transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                        initial="hidden"
                        whileInView="visible"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={{
                            visible: { opacity: 1, x: 0 },
                            hidden: { opacity: 0, x: 100 },
                        }}>
                        <div className="flex flex-col gap-10 w-full items-center justify-center">

                            {nextEvent.length > 0 ?

                                nextEvent.map((eve, idx) => (
                                    <div key={idx} className="xl:h-[310px] xl:max-w-[900px] max-w-[450px] min-w-[320px] flex xl:flex-row flex-col xl:justify-center rounded-2xl">

                                        {/* <div className="xl:hidden block h-[190px] w-full bg-no-repeat bg-cover rounded-t-2xl" style={{ backgroundImage: `url(${eve?.data?.coverUrl})` }}></div> */}
                                        <img src={eve?.data?.coverUrl} alt="" className="block xl:rounded-l-2xl" />

                                        <div className="flex flex-col justify-center gap-2.5 h-full w-full xl:rounded-r-2xl shadow-xl xl:max-w-[600px] xl:px-5 py-2.5 px-5 bg-violet xl:rounded-none rounded-b-2xl">

                                            <p className="xl:text-[24px] text-[16px] font-bold text-white uppercase">{eve?.data?.eventName}</p>

                                            <div className="flex xl:flow-row flex-col gap-2.5 text-white text-[14px] font-pressura">

                                                <div className="flex items-center gap-[5px] text-white font-pressura">
                                                    <IoMdCalendar />
                                                    <span className='xl:text-[14px] text-[14px]'>{format(parseISO(eve?.data?.date), "dd MMMM yyyy - HH'h'mm", { locale: fr })}</span>
                                                </div>

                                                {eve?.data?.type === 'presential' ?
                                                    <div className="flex items-center gap-[5px]">
                                                        <CiLocationOn />
                                                        <a href={eve?.data?.eventMapLink} className='xl:text-[14px] text-[14px]'>{eve?.data?.eventLinkOrPlace}</a>
                                                    </div>
                                                    :
                                                    <div className="flex items-center gap-[5px]">
                                                        <FaLink />
                                                        <a href={eve?.data?.eventLinkOrPlace} className='xl:text-[14px] text-[14px]'>Lien de participation</a>
                                                    </div>
                                                }

                                            </div>

                                            <Link to={"/events/event/" + eve.id + "#thisevent"} className="buttonwhite">En Savoir Plus</Link>

                                            <Link to="/events#localevents" className="flex items-center gap-1 pt-4 font-agrandir text-white text-[14px]">
                                                Voir tous les événements
                                                <FaAngleDoubleRight className='text-[14px]' />
                                            </Link>

                                        </div>

                                    </div>
                                ))
                                :
                                <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                                    <div className="flex flex-col">
                                        Aucun évènement annoncé !
                                        <span className="h-1 w-10 bg-tangerine" />
                                    </div>
                                </div>

                            }
                        </div>

                    </motion.div>

                </section>

                <section className="flex flex-col gap-[30px] xl:max-w-[1440px]">
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-[20px]">Récentes Publications</h1>
                    <motion.div
                        ref={ref}
                        transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 150 },
                        }}
                    >
                        <div className={`w-full h-full ${lastArticles.length > 0 ? 'grid' : 'flex'} xl:grid-cols-3 grid-flow-col overflow-x-auto whitespace-nowrap max-w-[100%] xl:gap-[80px] gap-5 justify-between items-center px-[5vw] pb-5`}>
                            {lastArticles.length > 0 ?
                                lastArticles.map((article, index) => (
                                    <div key={index} className="flex flex-col gap-2.5 pb-[20px] rounded-xl shadow-md  w-[300px] xl:w-[300px]">

                                        <div className="w-full  h-[200px] bg-cover rounded-xl shadow-md"
                                            style={{ backgroundImage: `url(${article?.data?.coverUrl})` }}></div>

                                        <div className="flex flex-col justify-center gap-2.5 h-full w-full xl:px-[20px] px-2.5 rounded-xl">

                                            <p className="xl:text-[20px] text-[16px] whitespace-break-spaces text-justify text-tangerine">{article?.data?.title}</p>

                                            <div className="flex items-center gap-[5px] text-grey font-pressura">
                                                <IoMdCalendar />
                                                <span className='xl:text-[14px] text-[10px]'>{article?.data?.createdAt && format(article?.data?.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}</span>
                                            </div>

                                            <div className="flex items-center justify-center text-violet text-[12px] py-[2px] px-2 w-fit border-[1px] border-violet rounded-xl">{article?.data?.rubrique}</div>

                                            <Link to={"/blog/article/" + article.id + "#article"} className="font-pressura flex items-center gap-1 text-aqua text-[14px]">
                                                Lire cet article
                                                <FaAngleDoubleRight className='text-[14px]' />
                                            </Link>

                                        </div>

                                    </div>
                                ))
                                :
                                <div className="flex justify-center w-full font-agrandir xl:px-[unset] px-[60px] xl:text-[18px] text-[15px]">
                                    <div className="flex flex-col">
                                        Nous n'avons encore rien posté. <br /> Revenez plus tard !
                                        <span className="h-1 w-10 bg-tangerine" />
                                    </div>
                                </div>
                            }
                        </div>
                    </motion.div>
                    {lastArticles.length > 0 &&
                        <div className="w-full flex justify-center items-center mt-5">
                            <Link to="/blog#blog" className="buttonviolet">Lire tous les articles</Link>
                        </div>
                    }

                </section>

                <PmiTogoData />

                <section className="flex flex-col justify-between items-center gap-[30px] max-w-[1440px]">
                    <p className="font-pressura xl:text-[24px] text-[16px] text-white bg-tangerine w-full text-center p-1">SPONSORS ET PARTENAIRES</p>
                    <motion.div
                        ref={ref}
                        transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 100 },
                        }}
                         className="sponsors">
                        <div className="sponsors-list">
                            <div className="sponsors-list-item">
                                <span className="badge">
                                    <img src={gold} alt="icon" />
                                </span>
                                <a href="" target="_blank">
                                    <img src={neurotech} alt="Neurotech"/>
                                </a>
                            </div>
                            <div className="sponsors-list-item">
                                <span className="badge">
                                    <img src={silver} alt="icon" />
                                </span>
                                <a href="https://togocom.tg/" target="_blank">
                                    <img src={togocom} alt="Togocom" />
                                </a>
                            </div>
                            <div className="sponsors-list-item cfao">
                                <a href="" target="_blank">
                                    <img src={cfao} alt="CFAO" />
                                </a>
                            </div>
                            <div className="sponsors-list-item">
                                <a href="https://pbservices.africa/new/" target="_blank">
                                    <img src={pbs} alt="Le partenaire du service numérique" />
                                </a>
                            </div>
                            <div className="sponsors-list-item inovact">
                                <a href="https://inovact.com/" target="_blank">
                                    <img src={inovact} alt="Inovact" />
                                </a>
                            </div>
                            <div className="sponsors-list-item">
                                <a href="https://leconomistedutogo.tg/" target="_blank">
                                    <img src={lecomomiste} alt="l'économiste" />
                                </a>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        ref={ref}
                        transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 100 },
                        }}
                    >
                        <div className="w-full xl:grid xl:grid-cols-1 gap-[40px] xl:px-[220px] xl:justify-evenly max-w-[1440px] items-center flex flex-col justify-center xl:pt-10">

                            <img src={aufLogo} alt="Logo AUF" className='xl:h-[100px] h-[50px]' />

                        </div>

                    </motion.div>
                </section>

            </div>

        </div>
    )
}

export default Home