import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firestoreDatabase } from '../../../../utils/firebase';
import InboxLine from './InboxLine';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FaAnglesLeft } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';

const Inbox = () => {

    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchMessages = async () => {
            const messagesRef = query(collection(firestoreDatabase, 'inbox'), orderBy('sentAt', 'desc'))
            onSnapshot(messagesRef, (snapshot) => {
                setMessages(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };
        fetchMessages();
    }, []);

    return (
        <div className="flex flex-col">

            <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
                <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Messagerie</p>

                <div className="xl:flex hidden justify-start items-center">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>
                <div className='bg-white flex flex-col gap-[5px] px-[60px] w-full'>

                    {messages.length > 0 ?
                        messages.map((message, idx) => (

                            <InboxLine key={idx}
                                id={message.id}
                                name={message.data.name}
                                sentAt={format(message.data.sentAt.toDate(), "dd MMMM yyyy - HH'h'mm", { locale: fr })}
                                read={message.data.read}
                            />
                        ))
                        :
                        <div className="flex justify-center w-full font-agrandir text-[18px]">
                            <div className="flex flex-col">
                                Vous n'avez reçu aucun message !
                                <span className="h-1 w-10 bg-tangerine" />
                            </div>
                        </div>
                    }

                </div>

            </section>

        </div>
    )
}

export default Inbox