import React, { useEffect, useState } from 'react';
import ArticleCard from '../../../components/article-card/ArticleCard';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../../utils/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import CategoryLine from './CategoryLine';
import { Link, useNavigate } from 'react-router-dom';
import { FaAnglesLeft } from 'react-icons/fa6';
import { IoMdSearch } from 'react-icons/io';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const Blog = () => {

    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        //read articles data from firebase
        const readArticlesData = async () => {
            const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', true))
            onSnapshot(articlesRef, (snapshot) => {
                setArticles(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };

        //read categories data from firebase
        const readCategoriesData = async () => {
            const categoriesRef = query(collection(firestoreDatabase, 'categories'))
            onSnapshot(categoriesRef, (snapshot) => {
                setCategories(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        };

        readCategoriesData();
        readArticlesData();

    }, []);


    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const handleSearch = async (e) => {
        try {
            e.preventDefault();
            setIsSearchLoading(true);
            const searchArticlesRef = query(collection(firestoreDatabase, 'articles'), where('titleLowerCase', '>=', searchTerm.toLowerCase()), where('isVisible', '==', true))
            onSnapshot(searchArticlesRef, (snapshot) => {
                setSearchResults(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } catch (error) {
            console.error('Error searching articles:', error);
        }
    };

    const endSearch = () => {
        setIsSearchLoading(false);
        setSearchTerm('');
    }

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[20px] xl:pb-[100px] pb-[80px]">

            <div className="xl:h-[30px] xl:block hidden" id='blog'></div>

            <section className="flex flex-col xl:gap-[50px]" id=''>
                <motion.div
                    ref={ref}
                    animate={inView ? 'visible' : 'hidden'}
                    variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: 1000 },
                    }}
                    transition={{ type: "spring", damping: 5, bounce: 0.6, duration: 0.9 }}
                >
                    <div className="xl:py-[30px] py-[15px] flex xl:justify-center items-center xl:bg-aqua slide-left">
                        <p className="font-pressura xl:text-[64px] text-[40px] xl:text-white text-aqua xl:px-[60px] px-5 xl:w-[1440px] slide-left">BLOG</p>
                    </div>
                </motion.div>
                <div className="xl:flex hidden xl:justify-center items-center xl:px-[60px] px-[20px]">
                    <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px] xl:w-[1440px]">
                        <FaAnglesLeft className='text-[10px]' />
                        Back
                    </Link>
                </div>

            </section>

            <div className="flex flex-col gap-5 xl:hidden px-[20px]">
                <form onSubmit={handleSearch} className="flex w-full">
                    <input type="search"
                        className='border-2 h-10 p-2 outline-none rounded-l-md w-full'
                        placeholder='Rechercher'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        required
                    />
                    <button type="submit" className='bg-violet text-white flex justify-center items-center h-10 w-10 rounded-r-md'>
                        <IoMdSearch />
                    </button>
                </form>

                {isSearchLoading &&
                    <div className="flex justify-end">
                        <button type="submit" onClick={endSearch} className='buttonviolet'>
                            Terminer la recherche
                        </button>
                    </div>
                }
            </div>
            <div className="xl:grid w-full justify-center">
                <div className="flex justify-between xl:max-w-[1440px]">
                    <section className="bg-white flex flex-col gap-[30px] xl:min-w-[850px] xl:w-4/6 w-full xl:pl-[60px] px-5">

                        <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">{isSearchLoading ? 'Résultats de la recherche' : 'Articles récents'}</p>

                        <div className="flex flex-col justify-center items-center w-full gap-[30px] xl:pr-[60px]">

                            {isSearchLoading ?

                                searchResults.length > 0 ? (

                                    searchResults.map((article, index) => (
                                        <ArticleCard
                                            key={index} {...article}
                                            id={article.id}
                                            coverImg={article.data.coverUrl}
                                            title={article.data.title}
                                            content={article.data.content}
                                            date={format(article.data.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                                        />
                                    ))
                                ) : (
                                    <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                                        <div className="flex flex-col">
                                            Nous n'avons rien trouvé, veuillez réessayer !
                                            <span className="h-1 w-14 bg-aqua" />
                                        </div>
                                    </div>
                                )
                                :
                                articles.length > 0 ? (

                                    articles.map((article, index) => (
                                        <ArticleCard
                                            key={index} {...article}
                                            id={article.id}
                                            coverImg={article.data.coverUrl}
                                            title={article.data.title}
                                            content={article.data.content}
                                            date={format(article.data.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                                        />
                                    ))
                                ) : (
                                    <div className="flex justify-center w-full font-agrandir xl:text-[18px] text-[15px]">
                                        <div className="flex flex-col">
                                            Nous n'avons encore rien posté. <br /> Revenez plus tard !
                                            <span className="h-1 w-14 bg-aqua" />
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </section>

                    <section className="bg-lightgrey xl:flex hidden flex-col gap-[50px] w-2/6 p-[60px] font-agrandir rounded-l-lg shadow-md">

                        <div className="flex flex-col gap-5 p-5 bg-white rounded-md shadow-md">
                            <p className="text-[18px]">Rechercher</p>

                            <form onSubmit={handleSearch} className="flex">
                                <input type="search"
                                    className='border-2 h-10 p-2 outline-none rounded-l-md w-full'
                                    placeholder=''
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    required
                                />
                                <button type="submit" className='bg-violet text-white flex justify-center items-center h-10 w-10 rounded-r-md'>
                                    <IoMdSearch />
                                </button>
                            </form>

                            {isSearchLoading &&
                                <div className="flex justify-end">
                                    <button type="submit" onClick={endSearch} className='buttonviolet'>
                                        Terminer la recherche
                                    </button>
                                </div>
                            }

                        </div>

                        <div className="flex flex-col gap-5 p-5 bg-white rounded-md shadow-md">
                            <p className="text-[18px]">Catégories</p>

                            <div className="flex flex-col gap-2.5 text-[16px]">

                                {categories.length > 0 ?
                                    categories.map((category, index) => (
                                        <CategoryLine
                                            key={index} {...categories}
                                            id={category.id}
                                            nom={category.data.nom}
                                        />
                                    ))
                                    :
                                    <p>---</p>
                                }

                            </div>
                        </div>

                    </section>
                </div>
            </div>

        </div >
    )
}

export default Blog