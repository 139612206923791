import React, { useEffect, useState } from 'react';
import BlogArticleCard from './BlogArticleCard';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../../../components/modal/Modal';
import { Timestamp, addDoc, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../../../utils/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FaAnglesLeft } from 'react-icons/fa6';


const BlogApp = () => {

  const [articles, setArticles] = useState([]);

  //read articles data from firebase
  useEffect(() => {
    const readArticlesData = async () => {
      const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', true))
      onSnapshot(articlesRef, (snapshot) => {
        setArticles(snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })
    };
    readArticlesData();
  }, []);

  const [nom, setNom] = useState("");
  const [error, setError] = useState('');


  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setShowSuccess(false);
  }

  //read rubriques data
  const [rubriqueOptions, setRubriqueOptions] = useState([])
  useEffect(() => {
    const rubriqueOptionRef = query(collection(firestoreDatabase, 'categories'), orderBy('nom', 'asc'))
    onSnapshot(rubriqueOptionRef, (snapshot) => {
      setRubriqueOptions(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  const addNewRubrique = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true); // Afficher le loader

      // Adding to Firebase Firestore
      await addDoc(collection(firestoreDatabase, "categories"), {
        nom: nom,
        createdAt: Timestamp.now()
      });

      setShowSuccess(true);
      setIsLoading(false);

      setNom('');

    } catch (error) {
      setError(error);
    }
  };

  const navigate = useNavigate();

  return (

    <div className="flex justify-between">

      <section className="bg-white flex flex-col gap-[30px] px-[60px] w-full">
        <p className="font-agrandir xl:text-[24px] text-[16px] text-tangerine">Articles publiés</p>
        <section className="flex pb-[30px]" id='blog'>

          <div className="xl:flex hidden justify-start items-center">
            <Link onClick={() => navigate(-1)} className="font-pressura flex items-center text-aqua text-[14px]">
              <FaAnglesLeft className='text-[10px]' />
              Back
            </Link>
          </div>

        </section>

        <div className="flex flex-col justify-center items-center gap-[30px] px-[50px]">
          {articles.length > 0 ?
            articles.map((article, index) => (
              <BlogArticleCard
                key={index} {...article}
                id={article.id}
                coverImg={article.data.coverUrl}
                title={article.data.title}
                date={format(article.data.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
              />
            ))
            :
            <div className="flex justify-center w-full font-agrandir text-[18px]">
              <div className="flex flex-col">
                Vous n'avez publié aucun article !
                <span className="h-1 w-10 bg-tangerine" />
              </div>
            </div>
          }
        </div>

        <div className="flex flex-col gap-5 fixed bottom-10 right-[30px] ">
          <Link to={"/dashboard/blogapp/new-article"} className='buttonblack'>Nouvel Article</Link>
          <button onClick={handleOpen} className='buttonblack'>Nouvelle Catégorie</button>
          <Link to={"/dashboard/blogapp/archives"} className='buttonblack'>Archives</Link>
        </div>

        <Modal isOpen={open} onClose={handleClose}>
          {error && <div className='auth__error'>{error}</div>}

          {showSuccess ? (
            <center className='font-agrandir flex flex-col items-center gap-5'>
              <p>Nouvelle Catégorie ajoutée !</p>
              <button type='button' className="buttonviolet" onClick={handleClose}>OK</button>
            </center>
          ) : (
            <div className="flex flex-col gap-5">
              <p className="text-violet">Ajouter une nouvelle catégorie d'article</p>

              <form onSubmit={addNewRubrique} name='addRubrique_form' className='font-agrandir flex flex-col gap-5 justify-center items-center' id='addRubriqueform'>
                <input
                  type="text"
                  className='outline-none border-2 px-4 py-2 rounded-md w-full'
                  placeholder="Nom de la nouvelle catégorie"
                  value={nom}
                  onChange={e => setNom(e.target.value)}
                  required />

                <button type='submit' className="buttonviolet">
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    <p>Ajouter</p>
                  )}
                </button>
              </form>

              <div className="flex flex-wrap w-full gap-2 border-2 cursor-pointer border-lightgrey p-2.5">
                {rubriqueOptions.map((rubriqueOption, index) => {
                  return (
                    <>
                      <div key={index} className="flex items-center justify-center text-violet text-[12px] py-[2px] px-2 w-fit border-[1px] border-violet rounded-xl">{rubriqueOption?.data?.nom}</div>
                    </>
                  )
                })}
              </div>
            </div>

          )}
        </Modal>

      </section>
    </div>
  )
}

export default BlogApp