import React from 'react'
import './we.css';
import communitySymbolWhite from '../../../assets/pmi/white/pmi_sym_comm_wht_rgb.png';
import weCoverImg from '../../../assets/images/we/we.jpg';
import teamworkSymbolTangerine from '../../../assets/pmi/tangerine/pmi_sym_tmwk_tang_rgb.png';
import commSymbolviol from '../../../assets/pmi/violet/pmi_sym_comm_viol_rgb.png';
import growthSymbolaqua from '../../../assets/pmi/aqua/pmi_sym_grwt_aqua_rgb.png';
import innoSymbolTangerine from '../../../assets/pmi/tangerine/pmi_sym_inno_tang_rgb.png';
import HeadTeamMender from '../../../components/HeadTeamMember/HeadTeamMender';
import VolunteersTeamMember from '../../../components/VolunteersTeamMember/VolunteersTeamMember';
import ObjectifMission from '../../../components/objectif-mission/ObjectifMission';
import { FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


/*HeadMember team */
import leonAmouzou from '../../../assets/images/we/headteammember/leon_amouzou.jpg';
import raissaDogbe from '../../../assets/images/we/headteammember/raissa_dogbe.jpeg';
import abireKazi from '../../../assets/images/we/headteammember/abire_kazi.png';
import komiTamakloe from '../../../assets/images/we/headteammember/komi_tamakloe.png';
import gabiAdotevi from '../../../assets/images/we/headteammember/gabi_adotevi.png';
import ezekielOcansey from '../../../assets/images/we/headteammember/ezekiel_ocansey.jpeg';
import dibekaBaman from '../../../assets/images/we/headteammember/dibekaBaman.jpg';

/*Volunteers team */
import valentineAgbekodo from '../../../assets/images/we/volunteers/valentineAgbekodo.jpg';
import aboudouNounhou from '../../../assets/images/we/volunteers/aboudou_nounhou.jpg';

const We = () => {

    const HeadTeamMembers = [
        {
            name: 'Léon Kokou B. AMOUZOU',
            role: 'Président',
            image: leonAmouzou,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/lkamouzou/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-violet'
        },
        {
            name: 'Gabi ADOTEVI',
            role: <p>Vice-Président <br /> Marketing & Sponsorship</p>,
            image: gabiAdotevi,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/gabi-adotevi-mba-9628a254/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-tangerine'
        },
        {
            name: 'Ezekiel Kanor OCANSEY',
            role: <p>Vice-Président <br /> Membership & Volontariat</p>,
            image: ezekielOcansey,
            socialLinks: [
                { url: 'www.linkedin.com/in/ezekiel-ocansey/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-aqua'
        },
        {
            name: 'Komi A.M. TAMAKLOE',
            role: 'Vice-Président Finance',
            image: komiTamakloe,
            socialLinks: [
                { url: 'linkedin.com/in/komi-akoli-tamakloe/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-violet'
        },
        {
            name: 'Raïssa Dogbe',
            role: <p>Vice-Président Education & <br /> Développement Professionnel</p>,
            image: raissaDogbe,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/ayawavi-raissa-dogbe-54700259/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-tangerine'
        },
        {
            name: 'Abiré Tchilalo Marie Reine KAZI',
            role: 'Vice-Président Evènementiel',
            image: abireKazi,
            socialLinks: [
                { url: 'www.linkedin.com/in/marie-reine-kazi-47310987/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-aqua'
        },
        {
            name: 'Dibéka BAMAN',
            role: 'Vice-Président Sécrétariat',
            image: dibekaBaman,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/dibeka-baman-502b8b177/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-tangerine'
        },

        // Ajoutez d'autres membres de l'équipe ici
    ];

    const VolunteersTeamMembers = [
        {
            name: 'Valentine AGBEKODO',
            role: 'Responsable Communication',
            image: valentineAgbekodo,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/valentine-agbekodo-187bb019b/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-tangerine'
        },
        {
            name: 'Aboudou Khatib  NOUNHOU',
            role: 'Responsable Evènementiel',
            image: aboudouNounhou,
            socialLinks: [
                { url: 'https://www.linkedin.com/in/khatib-nounhou-49b73356/', icon: <FaLinkedinIn /> },
            ],
            bgColor: 'bg-violet'
        },

        // Ajoutez d'autres membres de l'équipe ici
    ];

    const missions = [
        {
            symbole: teamworkSymbolTangerine,
            title: 'Développer des compétences',
            content: [
                'Permettre aux acteurs du domaine de la gestion de projets d’acquérir les bonnes compétences afin d’obtenir les meilleurs résultats grâce aux outils et ressources du PMI que nous mettons à disposition.',
            ],
            color: 'tangerine'
        },
        {
            symbole: commSymbolviol,
            title: 'Construire une communauté ',
            content: [
                'Développer une communauté dynamique de professionnels de la gestion de projets, leur permettre d’évoluer grâce au pouvoir du réseautage en leur offrant des opportunités qui contribueront au développement de leurs carrières.',
            ],
            color: 'violet'
        },
        {
            symbole: growthSymbolaqua,
            title: 'Contribuer au développement',
            content: [
                'Mettre les bonnes compétences, les bonnes ressources et les bonnes collaborations au service du développement du Togo. ',
            ],
            color: 'aqua'
        },
        {
            symbole: innoSymbolTangerine,
            title: 'Faire preuve d’innovation',
            content: [
                'Apporter des perspectives nouvelles et innovantes pour la résolution de problèmes quotidiens pour un développement continu.',
            ],
            color: 'tangerine'
        },
    ]

    const [ref, inView] = useInView({
        triggerOnce: true, // Pour que l'animation ne se produise qu'une fois
    });

    return (
        <div className="flex flex-col xl:gap-[100px] gap-[50px] pb-[100px] overflow-x-hidden">

            <div className="xl:h-[30px] xl:block hidden" id="we"></div>

            <section className="flex flex-col xl:gap-[30px] gap-[15px]">

                <div className="w-full xl:h-[350px] h-[210px] xl:px-[10vw] px-[60px] flex justify-center items-center bg-no-repeat bg-cover bg-center"
                    style={{ backgroundImage: `url(${weCoverImg})` }}>
                    <div className="flex justify-end items-center w-full max-w-[1440px]">
                        <motion.div
                            ref={ref}
                            animate={inView ? 'visible' : 'hidden'}
                            variants={{
                                visible: { opacity: 1, x: 0 },
                                hidden: { opacity: 0, x: -500 },
                            }}
                            transition={{ type: "linear", damping: 4, bounce: 0.6, duration: 1.9 }}
                        >
                            <img src={communitySymbolWhite} alt="" className="xl:w-[230px] w-[60px]" />
                        </motion.div>
                    </div>
                </div>

                <div className="grid w-full justify-center">
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[60px] px-5 xl:pb-5">PMI TOGO CHAPTER</h1>

                    <div className="xl:px-[100px] px-[20px] text-justify xl:text-[16px] text-[12px] flex flex-col items-center xl:gap-10 gap-5 font-agrandir xl:max-w-[1440px]">
                        <h3 className="">
                            Bienvenue au Chapitre Togolais du Project Management Institute (PMI) – votre passerelle vers l'excellence dans la gestion de projets au cœur du Togo !
                            <br /> <br />
                            Le Chapitre PMI TOGO est la branche locale du Project Management Institute, organisation de renommée mondiale spécialisée dans la gestion de projets, de programmes et de portefeuilles comptants plus de 600.000 membres à travers le monde.
                            <br /> <br />
                            Notre mission est de créer une communauté dynamique où les professionnels de la gestion de projets au Togo peuvent se connecter, apprendre et s'épanouir. Nous croyons en la puissance de la communauté pour stimuler l'innovation, partager les meilleures pratiques et élever les normes de la gestion de projets afin de bâtir l’excellence en gestion de projets pour un développement durable au Togo.
                            <br /> <br />
                            Que vous soyez un praticien expérimenté ou que vous aspiriez à embrasser les défis captivants de la gestion de projets, nous vous invitons chaleureusement à vous unir à la communauté florissante du Chapitre PMI TOGO.
                        </h3>

                        <div className="flex justify-center items-center bg-violet xl:p-5 p-2.5 border-2 shadow-md rounded-md xl:w-[50%] xl:max-w-[750px] max-w-[450px]">
                            <p className="text-center text-white">Alimenter l'économie de la gestion de projets au Togo et renforcer la société en donnant aux organisations et aux individus la capacité de transformer leurs idées en réalité.</p>
                        </div>

                    </div>
                </div>

            </section>

            <div className="xl:grid contents justify-center xl:gap-[100px] gap-[50px] xl:w-full">

                <section className="flex flex-col xl:gap-[30px] gap-[15px] xl:max-w-[1440px]" id='objectifs&missions'>

                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[100px] px-5">Objectifs et Missions</h1>

                    <div className="flex flex-col xl:gap-[90px] gap-[50px] justify-center items-center">

                        {missions.map((mission, idx) => (
                            <ObjectifMission
                                key={idx}
                                symbole={mission.symbole}
                                title={mission.title}
                                content={mission.content}
                                color={mission.color}
                            />
                        ))}

                        <div className="flex justify-center items-center">
                            <Link to={"https://www.pmi.org/membership/chapters/europe-middle-east-africa/togo/togo-chapter"} target="_blank" rel="noopener" className='buttonviolet'>Rejoignez-nous maintenant !</Link>
                        </div>

                    </div>

                </section>

                <section className="flex flex-col gap-[30px] xl:w-full xl:max-w-[1440px]" id='headmember'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[100px] px-5">Membres dirigeants</h1>

                    <div className="flex flex-col justify-center items-center gap-[50px] xl:px-[100px] pl-5">
                        <div className="grid grid-flow-col overflow-x-auto whitespace-nowrap transition volunteers max-w-[100%] gap-[50px] xl:px-[60px] px-5 pb-[50px]">

                            {HeadTeamMembers.map((member, index) => (
                                <HeadTeamMender key={index} {...member} />
                            ))}

                        </div>

                    </div>

                </section>

                <section className="flex flex-col gap-[30px] xl:w-full xl:max-w-[1440px]" id='volunteers'>
                    <h1 className="font-agrandir xl:text-[24px] text-[16px] text-tangerine xl:px-[100px] px-5">Volontaires</h1>

                    <div className="flex flex-col justify-center gap-[50px] xl:px-[100px] pl-5">
                        <div className="flex overflow-x-auto whitespace-nowrap transition volunteers max-w-[100%] gap-[50px] xl:px-[60px] px-5 pb-[50px]">

                            {VolunteersTeamMembers.map((member, index) => (
                                <VolunteersTeamMember key={index} {...member} />
                            ))}
                        </div>

                    </div>

                </section>

            </div>


        </div>
    )
}

export default We